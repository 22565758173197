import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { RatingSites } from '@rvpower/constants';
import { usePreamp } from '../../preamp';

import TXUFeaturedCard from './TXUFeaturedCard';

const TemplateTXUFeaturedCard = (props) => {
  const {
    comparedPlans,
    disableCartLinks,
    gridComparisonToolActive,
    onOrderOnline,
    plan,
    setComparedPlans,
    site,
    dwellingType,
  } = props;

  const { name } = plan;
  const { ratings } = plan.supplier;

  // react state
  const [planDetailsToggle, setPlanDetailsToggle] = useState(false);

  // preamp
  const ratingsPreamp = usePreamp('Star-Ratings');
  const swapExperience = usePreamp('Grid Swap Benefits and Price');

  // vars
  const ratingsEnabled = RatingSites.includes(site) ? true : ratingsPreamp;
  const showRatings = Boolean(ratingsEnabled && ratings);

  // refs
  const featuredCard = useRef(null);

  const checkbox = {
    checked: comparedPlans.filter((item) => item.name === name).length,
    label: 'CLICK TO COMPARE',
    name,
    value: name
  };

  const addToCompare = () => {
    setComparedPlans([...comparedPlans, plan]);
  };

  const removeFromCompare = (planToRemove) => {
    const newPlans = comparedPlans.filter((thisPlan) => thisPlan.name !== planToRemove);
    setComparedPlans(newPlans);
  };

  const showPlanDetails = (e) => {
    e.preventDefault();
    setPlanDetailsToggle(!planDetailsToggle);
  };

  return (
    <TXUFeaturedCard
      addToCompare={addToCompare}
      checkbox={checkbox}
      disableCartLinks={disableCartLinks}
      comparedPlans={comparedPlans}
      featuredCard={featuredCard}
      gridComparisonToolActive={gridComparisonToolActive}
      onOrderOnline={onOrderOnline}
      plan={plan}
      planDetailsToggle={planDetailsToggle}
      removeFromCompare={removeFromCompare}
      showRatings={showRatings}
      site={site}
      showPlanDetails={showPlanDetails}
      swapExperience={swapExperience}
      dwellingType={dwellingType}
    />
  );
};

export default TemplateTXUFeaturedCard;

TemplateTXUFeaturedCard.propTypes = {
  comparedPlans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  disableCartLinks: PropTypes.bool.isRequired,
  gridComparisonToolActive: PropTypes.bool.isRequired,
  onOrderOnline: PropTypes.func.isRequired,
  plan: PropTypes.shape({}).isRequired,
  site: PropTypes.string.isRequired,
  setComparedPlans: PropTypes.func.isRequired,
  dwellingType: PropTypes.string.isRequired,
};
