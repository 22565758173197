import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import tracking from '../../../../js/project/tagular/tracking';

import planFilterFn from '../../../../js/project/plan-filter';

import RadioButton from '../../radio-button';

const Rating = ({ allPlans, filters, setFilters }) => {
  // type of string and number
  const [isRadio, setIsRadio] = useState('Any');

  const anyCheckbox = {
    name: 'Any',
    value: 'Any',
    label: 'Any',
    checked: filters.ratings.length === 0,
  };

  const ratingsCheckboxes = [2.5, 3.5, 4.5].map((rating) => ({
    name: `ratings-${rating}`,
    value: rating,
    label: `${rating}+`,
    checked: filters.ratings.includes(rating),
    number: allPlans
      // first, filter plans without rating filter
      .filter(planFilterFn(filters, false))
      // then, filter the number of specific rating plans
      .filter((plan) => (plan.supplier.ratings?.overallScore || 0) >= rating).length
  }));

  // add anyCheckbox as the first element of ratesCheckbox
  ratingsCheckboxes.unshift(anyCheckbox);

  const handleChange = (e) => {
    const { value } = e.currentTarget;
    let newValue = value;

    // convert value to number if it's not any
    if (value !== 'Any') {
      newValue = parseFloat(newValue);
    }

    setIsRadio(newValue);

    tracking.elementClicked({
      webElement: {
        elementType: 'BUTTON',
        location: 'PROVIDER RATING FILTER',
        name: 'FILTER DROPDOWNS',
        position: '4',
        text: newValue.toString()
      },
      actionOutcome: 'FILTER LIST'
    });
  };

  useEffect(() => {
    if (isRadio === 'Any') {
      setFilters({
        ...filters,
        ratings: []
      });
      return;
    }

    setFilters({
      ...filters,
      ratings: [isRadio],
    });
  }, [isRadio]);

  const groupName = `rating-name-${uuidv4()}`;

  return (
    <div className="grid-v2-rating">
      {ratingsCheckboxes.map((rating) => {
        const {
          label,
          name,
          value,
          checked
        } = rating;

        const id = `rating-${uuidv4()}`;

        return (
          <RadioButton
            key={name}
            checked={checked}
            id={id}
            label={label}
            name={groupName}
            onChange={handleChange}
            starSVG
            value={value}
          />
        );
      })}
    </div>
  );
};

export default Rating;

Rating.propTypes = {
  allPlans: PropTypes.arrayOf(PropTypes.shape({
    supplier: PropTypes.shape({
      ratings: PropTypes.shape({
        overallScore: PropTypes.number
      })
    }).isRequired
  })).isRequired,
  filters: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    ratings: PropTypes.array.isRequired,
  }).isRequired,
  setFilters: PropTypes.func.isRequired
};
