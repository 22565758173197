/* eslint-disable max-len */
import { Sites } from '@rvpower/constants';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FeaturedCardLabel from '../../FeaturedCardLabel';
import FeaturedCardOverlay from '../../FeaturedCardOverlay';
import closeIcon from '../../../svg/close-button-white.svg';
import Checkbox from '../../Checkbox';
import GridBadges from '../../GridBadges';
import { usePreamp } from '../../preamp';
import tracking from '../../../js/project/tagular/tracking';

import CTA from '../sub-components/Cta';
import PricePerKwh from '../sub-components/PricePerKwh';
import Ratings from '../../Ratings';
import SatisfactionGuaranteeBadge from '../../SatisfactionGuaranteeBadge';

const getSatisfactionGuaranteeBadge = (badgesList = []) => {
  if (!badgesList || (badgesList && badgesList?.length === 0)) {
    return null;
  }

  const list = badgesList.filter((item) => item.name.includes('satisfaction'));

  if (list.length === 0) {
    return null;
  }

  return list[0];
};

function BasicFeaturedCard({
  ESIID,
  plan,
  onOrderOnline = () => { },
  site,
  gridComparisonToolActive,
  comparedPlans,
  disableCartLinks,
  featuredCard,
  showPlanDetails,
  showRatings,
  checkbox,
  addToCompare,
  removeFromCompare,
  planDetailsToggle,
  index,
}) {
  useEffect(() => {
    if (window.fuse) {
      window.fuse('placeNumbers');
    }
  }, []);

  const {
    name,
    supplier,
    type,
    badges,
    labelId
  } = plan;

  const isSOE = site === Sites.SOE;
  const SOEesiid = isSOE ? ESIID : '';
  const hasBadges = badges?.length > 0;
  const satisfactionGuaranteeBadgeActive = usePreamp('Satisfaction Guarantee Badge');
  const satisfactionGuaranteeBadge = getSatisfactionGuaranteeBadge(badges);

  const addSatisfactionGuaranteeStyle = (hasBadge = false, isActive = false) => {
    if (!isActive) {
      return '';
    }

    if (!hasBadge) {
      return 'featured-card__no-satisfaction-guarantee-badge';
    }

    return 'featured-card__has-satisfaction-guarantee-badge';
  };

  const showMoreInfo = (e) => {
    showPlanDetails(e);

    tracking.elementClicked({
      webElement: {
        location: 'FEATURED',
        elementType: 'Button',
        text: 'MORE INFO'
      },
      actionOutcome: 'Plan details expanded'
    });
  };

  const hideModeInfo = (e) => {
    showPlanDetails(e);
  };

  return (
    <article className={`featured-card ${gridComparisonToolActive ? 'gct-active' : ''} feature-card-labelId-${labelId}`} ref={featuredCard}>
      <FeaturedCardLabel plan={plan} index={index} />
      <div className="overlay__close-button-container">
        <button
          type="button"
          className={planDetailsToggle ? 'overlay__button overlay__button--active' : 'overlay__button'}
          onClick={hideModeInfo}
        >
          <img
            className="overlay__close-button"
            src={closeIcon}
            alt="close icon"
          />
        </button>
      </div>
      {(satisfactionGuaranteeBadge && satisfactionGuaranteeBadgeActive) && <SatisfactionGuaranteeBadge site={site} badge={satisfactionGuaranteeBadge} featuredCard />}
      <div className={`featured-card--overlay-container ${addSatisfactionGuaranteeStyle(satisfactionGuaranteeBadge, satisfactionGuaranteeBadgeActive)}`}>
        <div className="featured-card__section featured-card__plan-description-group">
          <div className="name">
            <p className="name__provider">{name}</p>
            <strong>{supplier.name}</strong>
          </div>
          <PricePerKwh
            plan={plan}
            site={site}
            className="card__section-item"
            featuredCard={featuredCard}
          />
        </div>
        <div
          className={`
            featured-card__section
            logo__container
            u-display-flex
            u-align-items-center
            ${showRatings ? 'logo__container--ratings' : ''}
          `}
        >
          <button
            type="button"
            className="featured-card__more-info-button-position more-info__button"
            onClick={showMoreInfo}
          >
            MORE INFO
          </button>
          {showRatings ? (
            <div className="logo-with-ratings">
              <img className="logo__image" src={supplier.logo} alt={supplier.name} />
              <Ratings
                ratings={supplier.ratings}
                boundary="scrollParent"
                tippyClass="ratings-tippy--card"
                supplierName={supplier.name}
                site={site}
              />
            </div>
          ) : (
            <img className="logo__image" src={supplier.logo} alt={supplier.name} />
          )}
        </div>
        {hasBadges && (
          <GridBadges
            badges={badges}
            site={site}
            featuredCard
          />
        )}
        {gridComparisonToolActive && (
          <div className="featured-card__section checkbox-row u-display-flex u-flex-direction-column">
            <Checkbox
              key={checkbox.name}
              checkbox={checkbox}
              addItem={addToCompare}
              removeItem={removeFromCompare}
              comparedPlans={comparedPlans}
              gridComparisonToolActive={gridComparisonToolActive}
            />
          </div>
        )}
        <div className="featured-card__section order u-display-flex u-flex-direction-column">
          <CTA
            ESIID={SOEesiid}
            plan={plan}
            site={site}
            disableCartLinks={disableCartLinks}
            onOrderOnline={onOrderOnline}
            className="card__section-item"
            featuredCard
          />
        </div>
        <FeaturedCardOverlay
          site={site}
          plan={plan}
          type={type}
          activeClass={planDetailsToggle}
        />
      </div>
    </article>
  );
}

BasicFeaturedCard.propTypes = {
  plan: PropTypes.shape({}).isRequired,
  site: PropTypes.string.isRequired,
  onOrderOnline: PropTypes.func.isRequired,
  gridComparisonToolActive: PropTypes.bool.isRequired,
  disableCartLinks: PropTypes.bool.isRequired,
  featuredCard: PropTypes.shape({
    current: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.shape({})
    ])
  }).isRequired,
  comparedPlans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showPlanDetails: PropTypes.func.isRequired,
  showRatings: PropTypes.shape({}),
  addToCompare: PropTypes.func.isRequired,
  removeFromCompare: PropTypes.func.isRequired,
  planDetailsToggle: PropTypes.bool.isRequired,
};

BasicFeaturedCard.defaultProps = {
  showRatings: false,
};

export default BasicFeaturedCard;
