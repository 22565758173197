import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import getPerMonthVerbage from '../../js/project/termination-fee';
import tracking from '../../js/project/tagular/tracking';
import { usePreamp } from '../preamp';
import { useMonarch } from '../monarch';

import CardDetailsTable from './CardDetailsTable';
import BadgeBox from './BadgeBox';
import openIcon from '../../svg/open.svg';
import TXUPlanPerks from './TXUPlanPerks';
import TXUWhatsIncluded from './TXUWhatsIncluded';
import TXUPlanDisclaimers from './TXUPlanDisclaimers';
import productDisclosure from '../../js/project/product-disclaimers';
import getIncentivePerSize from '../../js/project/incentivesUtils';

export default function TXUPlanDetails({
  plan,
  site,
  featured = false,
  className = '',
}) {
  const [activeSlide, setActiveSlide] = useState(0);
  const perMonthVerbage = getPerMonthVerbage(plan.fee?.monthly);
  const sliderTabs = ['Plan details', 'Price breakdown', 'Plan documents'];
  const coreLogicPreamp = usePreamp('Grid - Core Logic Sort') || false;
  const planDisclosure = productDisclosure(plan?.name);
  const { incentives } = plan;
  const incentive = getIncentivePerSize(incentives);
  const { rules } = useMonarch();
  const showIncentives = rules?.source?.incentives?.value?.showIncentives;

  let betterBusinessBadge = false;

  if (plan.badges) {
    plan.badges.forEach((badge) => {
      if (badge.name.includes('bbb')) betterBusinessBadge = badge;
    });
  }

  const trackDocumentClicked = (documentType) => {
    tracking.elementClicked({
      webElement: {
        location: featured ? 'FEATURED' : 'GRID',
        elementType: 'Link',
        text: documentType
      },
      actionOutcome: 'Plan docs opened'
    });
  };

  return (
    <>
      <div className={`plan-details ${className}`}>
        <>
          <div className="plan-details__slider">
            {sliderTabs.map((item, i) => (
              <button
                onClick={() => setActiveSlide(i)}
                type="button"
                key={item}
                className={`plan-details__slider-tab${activeSlide === i ? ' active' : ''}`}
                data-text={item}
              >
                {item}
              </button>
            ))}
          </div>
          <div className="plan-details__content">
            {activeSlide === 0 && (
              <>
                <TXUWhatsIncluded secondaryDescription={plan.secondaryDescription} />
                <TXUPlanPerks />
                <TXUPlanDisclaimers>
                  {planDisclosure && (
                    <p dangerouslySetInnerHTML={{ __html: planDisclosure }} />
                  )}
                  {showIncentives && incentive?.disclaimer && (
                    <p dangerouslySetInnerHTML={{ __html: `†${incentive?.disclaimer}` }} />
                  )}
                </TXUPlanDisclaimers>
              </>
            )}
            {activeSlide === 1 && (
              <>
                <p className="plan-details__content-title">Understanding your usage</p>
                <p className="plan-details__content-subtitle">based on your home type</p>
                <div className="plan-details__content-container">
                  <div
                    className={`plan-details__content-table ${coreLogicPreamp
                      ? 'grid-v2__prefill-table-container' : ''}`}
                  >
                    <CardDetailsTable
                      plan={plan}
                      electricPhoenixActive={false}
                    />
                  </div>
                  <div className="plan-details__legal-container">
                    {plan.fee && (
                      <div className="plan-details__fee">
                        <div className="plan-details__fee-amount">
                          <strong>{`Early cancellation fee${perMonthVerbage}: `}</strong>
                          {`$${plan.fee.amount}`}
                        </div>
                        <div className="plan-details__fee-note">
                          (Note: This fee will
                          <strong> not be charged </strong>
                          if you end your contract early because you are moving out.)
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            {activeSlide === 2 && (
              <div className="plan-details__content-container-trio">
                <div className="plan-details__document">
                  <p className="plan-details__document-label">Electricity Facts Label (EFL)</p>
                  <p className="plan-details__document-content">
                    An EFL breaks down plan info in more detail to understand a plan’s energy rate, fees, terms
                    and conditions and more.
                  </p>
                  <a
                    onClick={() => trackDocumentClicked('EFL')}
                    className="plan-details__document-cta"
                    href={plan?.EFLDoc?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Access the EFL
                    <img src={openIcon} alt="Open Icon" />
                  </a>
                </div>
                <div className="plan-details__document plan-details__document--middle">
                  <p className="plan-details__document-label">Terms of Service (TOS)</p>
                  <p className="plan-details__document-content">
                    The TOS explains the terms and conditions of your electric service with the provider.
                  </p>
                  <a
                    onClick={() => trackDocumentClicked('TOS')}
                    className="plan-details__document-cta"
                    href={plan?.TermsDoc?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Access the TOS
                    <img src={openIcon} alt="Open Icon" />
                  </a>
                </div>
                <div className="plan-details__document">
                  <p className="plan-details__document-label">Your Rights As a Consumer (YRAC)</p>
                  <p className="plan-details__document-content">
                    YRAC protects your rights by detailing all the info you should know about dealing with the provider.
                  </p>
                  <a
                    onClick={() => trackDocumentClicked('YRAC')}
                    className="plan-details__document-cta"
                    href={plan?.YRACDoc?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Access the YRAC
                    <img src={openIcon} alt="Open Icon" />
                  </a>
                </div>
              </div>
            )}
            {activeSlide === 3 && (
              <div
                className={betterBusinessBadge
                  ? 'plan-details__content-container-trio'
                  : 'plan-details__content-container-double'}
              >
                <div>
                  <p className="plan-details__content-know-title">What to know</p>
                  <p className="plan-details__content-subtitle">
                    {plan?.supplier?.whatToKnow || 'This provider has great energy plans at competitive rates.'}
                  </p>
                </div>
                <BadgeBox type="rating" plan={plan} site={site} />
                {betterBusinessBadge && (
                <BadgeBox type="bbb" plan={plan} badge={betterBusinessBadge} site={site} />
                )}
              </div>
            )}
          </div>
        </>
      </div>
    </>
  );
}

TXUPlanDetails.propTypes = {
  plan: PropTypes.shape({}).isRequired,
};
