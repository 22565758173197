/* eslint-disable react/jsx-no-target-blank */

import React, { useState } from 'react';

import useBreakpoints from '../../../js/hooks/useBreakPoints';
import tracking from '../../../js/project/tagular/tracking';

import NTXCardBadges from '../../cards/sub-components/NTXCardBadges';
import close from '../../../svg/close.svg';
import detailArrowSolid from '../../../svg/detail-arrow-solid-green-down.svg';
import soeLogo from '../../../../images/soe-logo.svg';
import solarTagMobile from '../../../../images/free-solar-estimate-tag-ltr.svg';
import solarTag from '../../../../images/free-solar-estimate-tag-rtl.svg';
import checkmarkIcon from '../../../../images/checkmark-green.svg';

import VisibleTracker from '../../VisibleTracker';

const SolarNonBrandCard = ({
  headline = 'Compare quotes from 8+ installers',
  ctaText = 'Get solar estimate',
  disclaimer = '*NREL Levelized Cost of Energy Analysis',
  kwhSavings = '7.8¢',
  avgSavings = '$15k',
  unitOfMeasure = 'KWH',
  label = 'Free solar estimate',
  trackElementViewed,
}) => {
  const {
    isSm, isMd, isLg, isXl
  } = useBreakpoints();
  const [detailsOpen, setDetailsOpen] = useState(false);

  const phoneNumber = '1-877-307-7668';
  const ctaLink = 'https://www.saveonenergy.com/solar-energy/solar-estimator/';
  const badge = [{ name: 'renewable' }];
  const uom = unitOfMeasure.toUpperCase();
  const checkmark1 = 'Learn your cost and savings';
  const checkmark2 = 'Explore financing options';
  const checkmark3 = 'Compare quotes from solar installers';
  const checkmarks = [checkmark1, checkmark2, checkmark3];

  const trackElementClicked = (element, action) => {
    tracking.elementClicked({
      webElement: {
        location: 'GRID - Solar Non-Brand Card',
        elementType: 'Button',
        text: element
      },
      actionOutcome: action
    });
  };

  const handleLinkOff = () => {
    trackElementClicked('Get solar estimate', 'Navigate to solar estimator');
  };

  const showHideDetails = () => {
    if (!detailsOpen) {
      trackElementClicked('More Details', 'More details expanded');
      if (isSm) {
        // Lock mobile viewport to prevent background from scrolling
        if (detailsOpen) document.body.classList.remove('grid-v2__mobile-locked');
        else document.body.classList.add('grid-v2__mobile-locked');
      }
    }
    if (detailsOpen) {
      const element = isSm ? 'X' : 'More details';
      trackElementClicked(element, 'More details closed');
      if (isSm) {
        document.body.classList.remove('grid-v2__mobile-locked');
      }
    }
    setDetailsOpen(!detailsOpen);
  };

  const renderChecklist = () => checkmarks.map((checkmark) => (
    <div className="solar-card__details__checkmark-list__item">
      <img
        alt="checkmark icon"
        className="solar-card__details__checkmark-list__icon"
        src={checkmarkIcon}
      />
      <div className="solar-card__details__checkmark-item">{checkmark}</div>
    </div>
  ));

  return (
    <VisibleTracker onVisible={() => trackElementViewed('Solar non-brand card')}>
      <div className="solar-card__wrapper">
        <div className="solar-card__top-section-wrapper">
          <div className="solar-card__top-section">
            <div className="solar-card__top-section__inner-wrapper">
              <img
                src={soeLogo}
                alt="SOE logo"
                className="solar-card__top-section__inner-wrapper__soe-logo"
              />
              <div className="solar-card__top-section__inner-wrapper__headline">
                {headline}
              </div>
            </div>
            <div>
              <img
                alt="Free solar estimate tag"
                className="solar-card__top-section__tag"
                src={isSm ? solarTagMobile : solarTag}
              />
            </div>
          </div>
          {/* Details Trigger Med and Large viewport */}
          {!isSm && (
            <>
              <button
                className={`button--details u-display-flex u-align-items-center ${detailsOpen ? 'button--open' : ''}`}
                id="ntx-plan-details-button"
                type="submit"
                onClick={showHideDetails}
              >
                <span>More details</span>
                <img
                  className={`svg svg--detail-arrow ${detailsOpen ? 'details-is-shown' : ''}`}
                  src={detailArrowSolid}
                  alt="Arrow"
                />
              </button>
                {detailsOpen && (
                <div className="solar-card__details">
                  <div className="solar-card__details__content">
                    <div className="solar-card__details__content__header">
                      Discover how much you could save by going solar
                    </div>
                    <div className="solar-card__details__checkmark-list">
                      {renderChecklist()}
                    </div>
                    <div className="solar-card__details__content__disclaimer">
                      {disclaimer}
                    </div>
                  </div>
                </div>
                )}
            </>
          )}
        </div>
        {isXl && (
        <div className="solar-card__badge-container">
          <NTXCardBadges badges={badge} featuredCard={false} />
        </div>
        )}
        <div className="solar-card__middle-section">
          <div className="solar-card__price-avg-wrapper">
            <div className="solar-card__price-avg-container">
              <p className="solar-card__price-avg-container__header">Price per</p>
              <p className="solar-card__price-avg-container__subheader">{`${uom}*`}</p>
              <strong className="solar-card__price-avg-container__value">{kwhSavings}</strong>
            </div>
            <div className="solar-card__price-avg-container">
              <p className="solar-card__price-avg-container__header">Average</p>
              <p className="solar-card__price-avg-container__subheader">20-YEAR SAVINGS</p>
              <strong className="solar-card__price-avg-container__value">{avgSavings}</strong>
            </div>
          </div>
          {/* Badge Container */}
          {(isMd || isLg) && !isSm && !isXl && (
            <div className="solar-card__badge-container">
              <NTXCardBadges badges={badge} featuredCard={false} />
            </div>
          )}
        </div>
        <div className="solar-card__cta-container" id="non-brand-cta-container">
          <div className="solar-card__cta-container-inner">
            <a
              className="solar-card__cta-button"
              href={ctaLink}
              id="ntx-cart-cta"
              target="_blank"
              onClick={handleLinkOff}
            >
              {ctaText}
            </a>
            <button
              type="submit"
              className="grid-button grid-button--ntx button--phone u-display-flex@lg u-justify-content-center@lg"
            >
              <a
                className="plan-card-v2__cta phone--with-icon"
                id="ntx-phone-cta"
                href={`tel:${phoneNumber}`}
              >
                <span className="ntx-phone-number-cta">{phoneNumber}</span>
              </a>
            </button>
          </div>
        </div>
        {/* Badge Container */}
        {isSm && !isXl && (
          <div className="solar-card__badge-container">
            <NTXCardBadges badges={badge} featuredCard={false} />
          </div>
        )}
        {/* Details trigger */}
        {isSm && (
          <div className="solar-card__bottom-section" id="card-details-trigger-ntx">
            <div className="u-display-flex u-align-items-center u-justify-content-space-between">
              <button
                className={`button--details u-display-flex u-align-items-center ${detailsOpen ? 'button--open' : ''}`}
                id="ntx-plan-details-button"
                type="submit"
                onClick={showHideDetails}
              >
                <span>More details</span>
              </button>
              {/* Details container */}
              {detailsOpen && (
              <div className="solar-card__details--mobile">
                <div className="solar-card__details--mobile__top-section">
                  <div className="solar-card__details--mobile__title-wrapper">
                    <div className="solar-card__details--mobile__title">{label}</div>
                  </div>
                  <div onClick={showHideDetails} role="button" tabIndex="0">
                    <img
                      alt="Close"
                      className="solar-card__details__close-icon"
                      src={close}
                    />
                  </div>
                </div>
                <div className="solar-card__details__content">
                  <div className="solar-card__details__content__header">
                    Discover how much you could save by going solar
                  </div>
                  <div className="solar-card__details__checkmark-list">
                    {renderChecklist()}
                  </div>
                  <div className="solar-card__details__content__disclaimer">
                    {disclaimer}
                  </div>
                </div>
              </div>
              )}
            </div>
          </div>
        )}
      </div>
    </VisibleTracker>
  );
};

export default SolarNonBrandCard;
