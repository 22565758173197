/* eslint-disable max-len */
import React from 'react';
import useToggle from '../../js/hooks/useToggle';

const ErrorBanner = ({ phone, isOpenInitial }) => {
  const [isOpen, toggleIsOpen] = useToggle(isOpenInitial);

  const handleOnClose = () => {
    toggleIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <div className="plans-widget-wrapper__error">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <g clipPath="url(#clip0_187_8486)">
              <path fillRule="evenodd" clipRule="evenodd" d="M8 14.8571C11.7871 14.8571 14.8571 11.7871 14.8571 8C14.8571 4.21291 11.7871 1.14286 8 1.14286C4.21291 1.14286 1.14286 4.2129 1.14286 8C1.14286 11.7871 4.2129 14.8571 8 14.8571ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 -3.86258e-07 12.4183 0 8C3.86258e-07 3.58172 3.58172 -3.86258e-07 8 0C12.4183 3.86258e-07 16 3.58172 16 8Z" fill="#CC494D" />
              <path fillRule="evenodd" clipRule="evenodd" d="M8 8.57143C7.68441 8.57143 7.42857 8.31559 7.42857 8L7.42857 4C7.42857 3.68441 7.68441 3.42857 8 3.42857C8.31559 3.42857 8.57143 3.68441 8.57143 4V8C8.57143 8.31559 8.31559 8.57143 8 8.57143Z" fill="#CC494D" />
              <path fillRule="evenodd" clipRule="evenodd" d="M9.14286 10.8571C9.14286 11.4883 8.63118 12 8 12C7.36882 12 6.85714 11.4883 6.85714 10.8571C6.85714 10.226 7.36882 9.71428 8 9.71428C8.63118 9.71428 9.14286 10.226 9.14286 10.8571Z" fill="#CC494D" />
            </g>
            <defs>
              <clipPath id="clip0_187_8486">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>
            Plans aren’t available at that ZIP code. Edit your location or call
            {' '}
            <a
              className="plans-widget-wrapper__error--phone"
              href={`tel:${phone}`}
              data-fuse
              data-fuse-format="(###) ###-####"
              data-fuse-name="ES-CTXP-FUSE-PERMA-ROG"
            >
              {phone}
            </a>
            {' '}
            for more help.
          </span>

          <button type="button" className="plans-widget-wrapper__error--close" onClick={handleOnClose} aria-label="Close">
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
              <g clipPath="url(#clip0_187_8489)">
                <path d="M4.62651 4L7.92771 7.3012C7.9759 7.37349 8 7.44578 8 7.51807C8 7.61446 7.9759 7.66265 7.92771 7.71084L7.71084 7.92771C7.66265 7.9759 7.59036 8 7.51807 8C7.42169 8 7.3494 7.9759 7.3012 7.92771L4 4.60241L0.698795 7.92771C0.626506 7.9759 0.554217 8 0.481928 8C0.385542 8 0.337349 7.9759 0.289157 7.92771L0.0722892 7.71084C0.0240964 7.66265 0 7.61446 0 7.51807C0 7.44578 0.0240964 7.37349 0.0722892 7.3012L3.39759 4L0.0722892 0.698795C0.0240964 0.650602 0 0.578313 0 0.481928C0 0.409639 0.0240964 0.337349 0.0722892 0.289157L0.289157 0.0722892C0.337349 0.0240964 0.385542 0 0.481928 0C0.554217 0 0.626506 0.0240964 0.698795 0.0722892L4 3.37349L7.3012 0.0722892C7.3494 0.0240964 7.42169 0 7.51807 0C7.59036 0 7.66265 0.0240964 7.71084 0.0722892L7.92771 0.289157C7.9759 0.337349 8 0.409639 8 0.481928C8 0.578313 7.9759 0.650602 7.92771 0.698795L4.62651 4Z" fill="#2B3A42" />
              </g>
              <defs>
                <clipPath id="clip0_187_8489">
                  <rect width="8" height="8" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      )}
    </>
  );
};

export default ErrorBanner;
