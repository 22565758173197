import React, { useState, useEffect } from 'react';

import gql from 'graphql-tag';
import { useApolloClient } from '@apollo/react-hooks';
import Autocomplete from 'react-autocomplete';
import beam, { energyTracking } from '../../../js/project/tagular/beam';

import iconPin from '../../../../images/icon-pin--hmc.svg';
import inputErrorIcon from '../../../../images/error-icon.svg';

const apiUrl = process.env.API_URL;
const appsyncUrl = process.env.APPSYNC_URL;
const getTokenEndpoint = `${apiUrl}/session/read-only?source=ac87aced-3761-402f-af86-98de0a658e81`;

const esiidQuery = `query address($addr: String!) {
  esiidAddress(address: $addr) {
      esiid
      address
      city
      state
      zipCode
      utilityId
  }
}`;

const esiidAddressQuery = `query esiidQuery($esiid: ID!) {
  esiid(esiid: $esiid) {
      address
      city
      esiid
      meterType
      premiseType
      state
      status
      switchHold
      utilityId
      zipCode
  }
}`;

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

// eslint-disable-next-line consistent-return
const getEsiidAddresses = async (address) => {
  const variables = { addr: address };

  try {
    const resp = await fetch(`${getTokenEndpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const token = await resp.json();
    if (token) {
      const response = await fetch(`${appsyncUrl}`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: token.token,
        },

        body: JSON.stringify({
          query: esiidQuery,
          variables,
        }),
      });

      const data = await response.json();

      return data;
    }
  } catch (error) {
    return error;
  }
};

// eslint-disable-next-line consistent-return
const getAddressFromEsiid = async (esiid, setInputValue) => {
  const variables = { esiid };

  try {
    const resp = await fetch(`${getTokenEndpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const token = await resp.json();
    if (token) {
      const response = await fetch(`${appsyncUrl}`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: token.token,
        },

        body: JSON.stringify({
          query: esiidAddressQuery,
          variables,
        }),
      });

      const data = await response.json();
      const addressData = data?.data?.esiid;

      if (addressData) {
        const fullAddress = `${addressData.address}, ${addressData.city}, ${addressData.state} ${addressData.zipCode}`;

        localStorage.setItem('fullAddress', fullAddress);
        setInputValue(fullAddress);
      }
      return data;
    }
  } catch (error) {
    return error;
  }
};

const HmcAddressCapture = (props) => {
  const {
    modalOpen,
    skipHandler,
    updateIsLoading,
    updateModalHandler,
    updateHmcData,
    updateCorelogicFail,
    setSort,
  } = props;

  const [items, setItems] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [addressDetails, setAddressDetails] = useState({});

  const client = useApolloClient();

  const debouncedInputValue = useDebounce(inputValue, 500) || '';

  // eslint-disable-next-line max-len
  const formatAddress = (address) => `${address.address}, ${address.city}, ${address.state.toUpperCase()} ${address.zipCode}`;

  const handleEsiidLookup = async (val, setLocalStorage = false) => {
    if (val.length > 3 && (debouncedInputValue || setLocalStorage)) {
      const data = await getEsiidAddresses(val);

      const formatData = Object.keys(data).map((key) => data[key]);


      setItems(formatData[0].esiidAddress);

      if (setLocalStorage && formatData[0].esiidAddress[0].address) {
        localStorage.setItem('fullAddress', formatData[0].esiidAddress[0].address);
      }
    }
  };

  useEffect(() => {
    handleEsiidLookup(inputValue);
  }, [debouncedInputValue, inputValue]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const ESIID = params.get('esiid');

    if (ESIID) {
      getAddressFromEsiid(ESIID, setInputValue);
    }

    const storedAddress = localStorage.getItem('fullAddress');

    if (storedAddress) {
      setInputValue(storedAddress);
    }
  }, []);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const onSelect = (_, val) => {
    const fullAddress = formatAddress(val);
    setInputValue(fullAddress);
    localStorage.setItem('esiid', val.esiid);
    localStorage.setItem('fullAddress', fullAddress);

    setAddressDetails(val);

    const addressCapturedPayload = {
      activityDatetime: new Date().toISOString(),
      integrationContext: {
        // eslint-disable-next-line no-underscore-dangle
        websessionId: window?._Cohesion.sessionId,
        // eslint-disable-next-line no-underscore-dangle
        processId: window?._Cohesion.sessionId,
        senderId: 'Save On Energy grid',
      },
      address: fullAddress,
      esiid: val.esiid,
      addressId: val.esiid,
      postalCode: val.zipCode,
      state: val.state,
    };
    energyTracking('AddressCaptured', addressCapturedPayload, 'v2', false);
  };

  const renderMenu = (menuItems) => {
    if (menuItems) {
      return (
        <ul
          className="hmc-capture-esiid-address-lookup__menu"
        >
          {menuItems}
        </ul>
      );
    }
    return [];
  };

  const skipClickHandler = () => {
    const skipButtonPayload = {
      webElement: {
        location: 'Address Entry',
      },
      actionOutcome: 'Skip Address Step'
    };
    beam.userTrackingV3('ElementClicked', skipButtonPayload);

    skipHandler();
  };

  const esiid = localStorage.getItem('esiid');

  const corelogicKwhQuery = gql`
  query corelogicEstimatedkwh($esiid: ID!) {
    corelogicEstimatedkwh(esiid: $esiid)
  }
`;

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!esiid) {
      setIsValid(false);
      return;
    }

    const continueButtonPayload = {
      formContext: {
        formName: 'Help Me Choose',
        formType: 'Questionnaire',
      },
      stepContext: {
        stepName: 'Address Entry',
        stepNumber: 1,
      }
    };
    beam.userTrackingV3('FormContinued', continueButtonPayload);

    updateIsLoading(true);
    try {
      const { data } = await client.query({
        query: corelogicKwhQuery,
        variables: { esiid },
      });

      const corelogicCalledPayload = {
        // eslint-disable-next-line no-underscore-dangle
        messageID: window?._Cohesion.sessionId,
        sentAt: new Date().toISOString(),
        // eslint-disable-next-line no-underscore-dangle
        msgWebContextAnonymousID: window?._Cohesion.anonymousId,
        // eslint-disable-next-line no-underscore-dangle
        msgWebContextUserAgent: window?._Cohesion.userAgent,
        // eslint-disable-next-line no-underscore-dangle
        tenantID: window?._Cohesion.tenantId,
        // eslint-disable-next-line no-underscore-dangle
        msgWebContextWebSessionID: window?._Cohesion.sessionId,
        // eslint-disable-next-line no-underscore-dangle
        msgWebContextInstanceID: window?._Cohesion.instanceId,
        apiToken: 'corelogicEstimatedkwh',
        streetAddress: addressDetails.address,
        city: addressDetails.city,
        state: addressDetails.state,
        zipCode: addressDetails.zipCode,
        date: new Date().toISOString()
      };
      energyTracking('CoreLogicCalled', corelogicCalledPayload, 'v2', false);

      // unmount form and mount loader, reset fail states, and update hmcData
      updateIsLoading(true);
      updateCorelogicFail(false);
      setIsValid(true);

      updateHmcData({
        form: 'HmcAddressCapture',
        estimatedUsage: data?.corelogicEstimatedkwh,
      });

      setSort('rate-low');

      // close drawer after loader animation
      setTimeout(() => {
        const cateredGridPayload = {
          pageName: 'Help Me Choose Grid'
        };
        beam.userTrackingV2('PageViewed', cateredGridPayload);

        updateModalHandler();
      }, 5500);

      // ensure unmounting of the loader after drawer close animation
      setTimeout(() => {
        updateIsLoading(false);
      }, 5800);
    } catch (error) {
      // catching error: graphql 404
      updateCorelogicFail(true);
      updateHmcData({
        form: 'HmcAddressCapture',
        estimatedUsage: null,
      });
      skipHandler();

      setTimeout(() => {
        updateIsLoading(false);
      }, 5500);
    }
  };

  return (
    <form
      id="hmc-address-capture"
      className="hmc-capture"
      onSubmit={(e) => submitHandler(e)}
    >
      <div className="hmc-capture__title-container">
        <h2 className="hmc-capture__header">Enter your home address</h2>
        <p className="hmc-capture__subheader">
          We use your address to calculate your monthly kWh usage and provide personal recommendations.
        </p>
      </div>
      <div className={`
        esiid-address-lookup__container
        hmc-capture-esiid-address-lookup__container
        ${!isValid ? 'hmc-capture-esiid-address-lookup__container--invalid' : ''}
        `}
      >
        <img
          className={`
            esiid-address-lookup__icon
            hmc-capture-esiid-address-lookup__icon
            ${!isValid ? 'hmc-capture-esiid-address-lookup__icon--invalid' : ''}
          `}
          src={iconPin}
          alt="Address"
        />
        <Autocomplete
          getItemValue={(item) => item.address}
          inputProps={{
            placeholder: 'Enter your address',
            className: `
              esiid-address-lookup__input
              ${!isValid ? 'hmc-capture-esiid-address-lookup__input--invalid' : ''}
            `,
            tabIndex: modalOpen ? '0' : '-1',
          }}
          items={items || []}
          onChange={handleChange}
          onSelect={onSelect}
          renderItem={(item, isHighlighted) => (
            <li
              className="esiid-address-lookup__list-item"
              key={item.esiid}
              style={{
                background: isHighlighted ? '#F2F1F1' : '#FFFFFF',
              }}
            >
              {formatAddress(item).toUpperCase()}
            </li>
          )}
          renderMenu={renderMenu}
          value={inputValue}
          wrapperStyle={{ border: 'none' }}
        />
        {!isValid && (
          <div className="hmc-capture-esiid-address-lookup__error-container">
            <img
              src={inputErrorIcon}
              className="hmc-capture-esiid-address-lookup__error-icon"
              alt="Error icon"
            />
            <p className="hmc-capture-esiid-address-lookup__error-message">Please enter a valid address.</p>
          </div>
        )}
      </div>
      <div className="hmc-drawer__bottom">
        <button
          type="button"
          className="hmc-drawer__bottom-modifier-button"
          onClick={skipClickHandler}
          tabIndex={modalOpen ? '0' : '-1'}
        >
          Skip
        </button>
        <button
          form="hmc-address-capture"
          type="submit"
          className="hmc-drawer__bottom-continue-button"
          tabIndex={modalOpen ? '0' : '-1'}
          disabled={!inputValue}
        >
          Continue
        </button>
      </div>
    </form>
  );
};

export default HmcAddressCapture;
