// This is rule is to use the dangerouslySetInnerHTML prop to render the content of the banner
/* eslint-disable react/no-danger */
import React from 'react';
import tracking from '../../js/project/tagular/tracking';
import VisibleTracker from '../VisibleTracker';

// Phone icons
import choosePhoneIcon from '../../svg/icon-phone-choose.svg';
import soePhoneIcon from '../../svg/soe-phone-icon.svg';
import txerPhoneIcon from '../../svg/txer-phone-icon.svg';
import ctxpPhoneIcon from '../../svg/ctxp-phone-icon.svg';
// Lightbulb icons
import lightbulbIconChoose from '../../svg/lightbulb-choose.svg';
import lightbulbIconSOE from '../../svg/lightbulb-soe.svg';
import lightbulbIconTXER from '../../svg/lightbulb-txer.svg';
import lightbulbIconCTXP from '../../svg/lightbulb-ctxp.svg';

const themes = {
  'saveonenergy.com': {
    lightbulb: lightbulbIconSOE,
    class: 'soe',
    phoneIcon: soePhoneIcon,
    begLink: '/business-energy/'
  },
  'chooseenergy.com': {
    lightbulb: lightbulbIconChoose,
    class: 'choose',
    phoneIcon: choosePhoneIcon,
    begLink: '/for-business/'
  },
  'new.texaselectricrates.com': {
    lightbulb: lightbulbIconTXER,
    class: 'new-txer',
    phoneIcon: txerPhoneIcon,
    begLink: '/business-rates-form/'
  },
  'new.choosetexaspower.org': {
    lightbulb: lightbulbIconCTXP,
    class: 'new-ctxp',
    phoneIcon: ctxpPhoneIcon,
    begLink: '/business-rates-form/'
  }
};

function formatPhoneNumber(phoneNumber) {
  const cleaned = (`${phoneNumber}`).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phoneNumber;
}

// Adjusts text that includes phone number
function createDesktopText(desktopText, phoneNumber) {
  const phoneLink = `<a href="tel:+1${phoneNumber}" class="cta cta__desktop">${formatPhoneNumber(phoneNumber)}</a>`;
  if (!desktopText.includes('phoneNumber')) {
    return desktopText;
  }
  const updatedText = desktopText.replace('phoneNumber', phoneLink);
  return updatedText;
}

const BegEntryBanner = ({ site, bannerContent }) => {
  const theme = site in themes ? themes[site] : null;
  // if not includes in themes don't show the banner
  if (theme === null) {
    return null;
  }

  // if no banner content don't show the banner
  if (!bannerContent) {
    return null;
  }

  const handleClick = () => {
    tracking.elementClicked({
      webElement: {
        location: 'grid',
        elementType: 'link',
        text: 'Learn More',
        name: bannerContent.ctaText,
      },
    });
  };

  const elementViewedPayload = {
    webElement: {
      name: 'BEG Grid Banner CTA',
      position: 'grid',
      text: bannerContent.ctaText,
    }
  };

  const updatedText = createDesktopText(bannerContent.desktopText, bannerContent.phoneNumber);

  return (
    <VisibleTracker
      onVisible={() => tracking.elementViewed(elementViewedPayload)}
    >
      <div className={`beg-entry-banner ${theme.class}`}>
        <div className="text-container">
          <div className="icon-wrapper">
            <img className="icon" src={theme.lightbulb} alt="lightbulb" />
          </div>
          <div className="text-wrapper">
            <p className="text-heading">{bannerContent.title}</p>
            <span
              className="desktop-phone-text"
              dangerouslySetInnerHTML={{ __html: updatedText }}
            />
            <div className="mobile-phone-text">
              <a
                href={`tel:+1${bannerContent.phoneNumber}`}
                className="cta cta__mobile"
              >
                <img src={theme.phoneIcon} alt="phone-icon" />
                <span>{bannerContent.mobileText}</span>
              </a>
              <hr />
              <a
                href={`${theme.begLink}`}
                className="cta cta__beg-mobile"
                onClick={handleClick}
                target="_blank"
                rel="noopener noreferrer"
              >
                {bannerContent.ctaText}
              </a>
            </div>
          </div>
        </div>
        <a
          href={`${theme.begLink}`}
          className="cta cta__beg-desktop"
          onClick={handleClick}
          target="_blank"
          rel="noopener noreferrer"
        >
          {bannerContent.ctaText}
        </a>
      </div>
    </VisibleTracker>
  );
};

export default BegEntryBanner;
