import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { UsageAmounts } from '@rvpower/constants';

export default function TxuPrice({
  plan,
  isFeaturedCard
}) {
  const {
    price,
    usageId,
    prices,
    unitOfMeasure: { currency }
  } = plan;

  const [featuredUsage, setFeaturedUsage] = useState('1000');
  const updatedPrice = prices[featuredUsage];

  const displayPrice = Number(
    (isFeaturedCard ? updatedPrice : price) * 100
  ).toFixed(1);

  useEffect(() => {
    if (usageId && usageId !== '') {
      setFeaturedUsage(UsageAmounts[usageId].label);
    }
  }, [usageId]);

  return (
    <div className="txu-prices">
      <span className="price__digits">
        {displayPrice}
        {currency || '¢'}
      </span>
    </div>
  );
}

TxuPrice.propTypes = {
  plan: PropTypes.shape({}).isRequired,
  isFeaturedCard: PropTypes.bool,
};

TxuPrice.defaultProps = {
  isFeaturedCard: false
};
