import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippy.js/react';

import { Sites } from '@rvpower/constants';
import infoBubble from '../svg/info-bubble.svg';
import iconCheck from '../../images/icon-check.svg';
import { usePreamp } from './preamp';

const assetNames = {
  'Satisfaction Guarantee Badge': 'default',
  'Satisfaction Guarantee Variation Badge': 'variant'
};

const daysGuarantee = {
  30: '30',
  60: '60',
  90: '90'
};

const badgeStyle = {
  [Sites.CHOOSE]: {
    [daysGuarantee[30]]: {
      default: {
        iconFilter: 'invert(29%) sepia(12%) saturate(6203%) hue-rotate(205deg) brightness(90%) contrast(87%)',
        textColor: '#3B4DA0'
      },
      variant: {
        backgroundColor: '#DDE1F3',
        iconFilter: 'invert(27%) sepia(27%) saturate(2648%) hue-rotate(204deg) brightness(92%) contrast(85%)',
        textColor: '#3A3A3A',
        fontWeight: 700,
        satisfactionTextColor: '#3A3A3A',
      }
    },
    [daysGuarantee[60]]: {
      default: {
        iconFilter: 'invert(24%) sepia(23%) saturate(1778%) hue-rotate(249deg) brightness(92%) contrast(86%)',
        textColor: '#6A356F'
      },
      variant: {
        backgroundColor: '#F3E7F4',
        iconFilter: 'invert(22%) sepia(45%) saturate(1042%) hue-rotate(250deg) brightness(94%) contrast(85%)',
        textColor: '#3A3A3A',
        fontWeight: 700,
        satisfactionTextColor: '#3A3A3A',
      }
    },
    [daysGuarantee[90]]: {
      default: {
        iconFilter: 'invert(48%) sepia(30%) saturate(1168%) hue-rotate(289deg) brightness(102%) contrast(101%)',
        textColor: '#FF5C97'
      },
      variant: {
        backgroundColor: '#F3E7F4',
        iconFilter: 'invert(58%) sepia(56%) saturate(3911%) hue-rotate(307deg) brightness(104%) contrast(104%)',
        textColor: '#3A3A3A',
        fontWeight: 700,
        satisfactionTextColor: '#3A3A3A',
      }
    },
  },
  [Sites.NEW_CTXP]: {
    [daysGuarantee[30]]: {
      default: {
        iconFilter: 'invert(26%) sepia(100%) saturate(1690%) hue-rotate(337deg) brightness(119%) contrast(86%)',
        textColor: '#EE474C'
      },
      variant: {
        backgroundColor: '#FFF6F6',
        iconFilter: 'invert(52%) sepia(75%) saturate(5600%) hue-rotate(334deg) brightness(98%) contrast(91%)',
        textColor: '#272727',
        fontWeight: 700,
        satisfactionTextColor: '#272727',
      }
    },
    [daysGuarantee[60]]: {
      default: {
        iconFilter: 'invert(64%) sepia(42%) saturate(5854%) hue-rotate(16deg) brightness(96%) contrast(77%)',
        textColor: '#B9881D'
      },
      variant: {
        backgroundColor: '#FFFCF2',
        iconFilter: 'invert(80%) sepia(43%) saturate(469%) hue-rotate(351deg) brightness(94%) contrast(93%)',
        textColor: '#272727',
        fontWeight: 700,
        satisfactionTextColor: '#272727',
      }
    },
    [daysGuarantee[90]]: {
      default: {
        iconFilter: 'invert(19%) sepia(31%) saturate(433%) hue-rotate(156deg) brightness(90%) contrast(90%)',
        textColor: '#2B3A42'
      },
      variant: {
        backgroundColor: '#F3F9FF',
        iconFilter: 'invert(17%) sepia(35%) saturate(386%) hue-rotate(156deg) brightness(99%) contrast(89%)',
        textColor: '#272727',
        fontWeight: 700,
        satisfactionTextColor: '#272727',
      }
    },
  },
  [Sites.SOE]: {
    [daysGuarantee[30]]: {
      default: {
        iconFilter: 'invert(17%) sepia(50%) saturate(720%) hue-rotate(223deg) brightness(99%) contrast(88%)',
        textColor: '#44305F'
      },
      variant: {
        backgroundColor: '#F1E5FF',
        iconFilter: 'invert(19%) sepia(27%) saturate(1193%) hue-rotate(223deg) brightness(94%) contrast(90%)',
        textColor: '#535353',
        fontWeight: 700,
        satisfactionTextColor: '#535353',
      }
    },
    [daysGuarantee[60]]: {
      default: {
        iconFilter: 'invert(59%) sepia(15%) saturate(2076%) hue-rotate(142deg) brightness(89%) contrast(77%)',
        textColor: '#309DB1'
      },
      variant: {
        backgroundColor: '#CCF1F7',
        iconFilter: 'invert(52%) sepia(54%) saturate(2061%) hue-rotate(151deg) brightness(98%) contrast(101%)',
        textColor: '#535353',
        fontWeight: 700,
        satisfactionTextColor: '#535353',
      }
    },
    [daysGuarantee[90]]: {
      default: {
        iconFilter: 'invert(42%) sepia(28%) saturate(2636%) hue-rotate(114deg) brightness(99%) contrast(101%)',
        textColor: '#00AA4E'
      },
      variant: {
        backgroundColor: '#D4FFC1',
        iconFilter: 'invert(37%) sepia(66%) saturate(2838%) hue-rotate(129deg) brightness(98%) contrast(101%)',
        textColor: '#3C3C3C',
        fontWeight: 700,
        satisfactionTextColor: '#3C3C3C',
      }
    },
  },
  [Sites.NEW_TXER]: {
    [daysGuarantee[30]]: {
      default: {
        iconFilter: 'invert(20%) sepia(68%) saturate(6487%) hue-rotate(203deg) brightness(96%) contrast(107%)',
        textColor: '#0160D4'
      },
      variant: {
        backgroundColor: '#CFE5FF',
        iconFilter: 'invert(32%) sepia(93%) saturate(7500%) hue-rotate(206deg) brightness(97%) contrast(99%)',
        textColor: '#0B3970',
        fontWeight: 700,
        satisfactionTextColor: '#0B3970',
      }
    },
    [daysGuarantee[60]]: {
      default: {
        iconFilter: 'invert(36%) sepia(87%) saturate(484%) hue-rotate(150deg) brightness(91%) contrast(93%)',
        textColor: '#11799F'
      },
      variant: {
        backgroundColor: '#DDF2FF',
        iconFilter: 'invert(72%) sepia(27%) saturate(1145%) hue-rotate(165deg) brightness(102%) contrast(98%)',
        textColor: '#0B3970',
        fontWeight: 700,
        satisfactionTextColor: '#0B3970',
      }
    },
    [daysGuarantee[90]]: {
      default: {
        iconFilter: 'invert(24%) sepia(52%) saturate(5024%) hue-rotate(188deg) brightness(95%) contrast(100%)',
        textColor: '#0075BD'
      },
      variant: {
        backgroundColor: '#DFF3FF',
        iconFilter: 'invert(40%) sepia(77%) saturate(1933%) hue-rotate(180deg) brightness(103%) contrast(104%)',
        textColor: '#0B3970',
        fontWeight: 700,
        satisfactionTextColor: '#0B3970',
      }
    },
  },
  default: {
    iconFilter: 'invert(20%) sepia(23%) saturate(1184%) hue-rotate(223deg) brightness(97%) contrast(96%)',
    backgroundColor: '#F6F6F6',
    textColor: '#3A3A3A',
    fontWeight: 'normal',
    satisfactionTextColor: '#3A3A3A',
  }
};

const getDayFromTooltip = (tooltipText = '', daysGuaranteeObject) => {
  // ⚠️@TODO: Revisit updating eslint; not using template literal to avoid error with current version of eslint
  // eslint-disable-next-line prefer-template
  if (tooltipText?.includes(daysGuaranteeObject[30] + ' days')) {
    return daysGuaranteeObject[30];
  }

  // ⚠️@TODO: Revisit updating eslint; not using template literal to avoid error with current version of eslint
  // eslint-disable-next-line prefer-template
  if (tooltipText?.includes(daysGuaranteeObject[60] + ' days')) {
    return daysGuaranteeObject[60];
  }

  // ⚠️@TODO: Revisit updating eslint; not using template literal to avoid error with current version of eslint
  // eslint-disable-next-line prefer-template
  if (tooltipText?.includes(daysGuaranteeObject[90] + ' days')) {
    return daysGuaranteeObject[90];
  }

  return '';
};

const getContainerModifierStyle = (isFeaturedCard = false) => {
  let modifier = 'basic-card';

  if (isFeaturedCard) {
    modifier = 'featured-card';
  }

  return `satisfaction-guarantee-badge--${modifier}`;
};

const SatisfactionGuaranteeBadge = ({ badge, featuredCard, site }) => {
  const assetName = usePreamp('Satisfaction Guarantee Badge');
  const days = getDayFromTooltip(badge?.tooltip, daysGuarantee);

  const contentStyle = {
    ...badgeStyle.default,
    ...(badgeStyle?.[site]?.[days]?.[assetNames[assetName]] ? badgeStyle[site][days][assetNames[assetName]] : {})
  };

  const style = {
    container: {
      backgroundColor: contentStyle.backgroundColor,
    },
    icon: {
      filter: contentStyle.iconFilter,
    },
    text: {
      color: contentStyle.textColor,
      fontWeight: 'bold',
      marginRight: 4,
    },
    satisfactionText: {
      fontWeight: contentStyle.fontWeight,
      color: contentStyle.satisfactionTextColor,
    },
  };

  return (
    <div className={`badge-container__badge ${getContainerModifierStyle(featuredCard)}`} style={style.container}>
      <img className="badge-container__icon" src={iconCheck} alt={badge?.name} style={style.icon} />
      {days ? <span style={style.text}>{`${days}-Day`}</span> : null}
      <span style={style.satisfactionText}>Satisfaction Guarantee</span>
      {badge?.tooltip ? (
        <Tippy content={badge.tooltip} arrow placement="top-start" className="disclaimer">
          <img className="svg svg--info-bubble badge-container__tooltip" src={infoBubble} alt="Info" />
        </Tippy>
      ) : null}
    </div>
  );
};

export default SatisfactionGuaranteeBadge;

SatisfactionGuaranteeBadge.propTypes = {
  badge: PropTypes.shape().isRequired,
  site: PropTypes.oneOf(Object.values(Sites)).isRequired,
  featuredCard: PropTypes.bool,
};

SatisfactionGuaranteeBadge.defaultProps = {
  featuredCard: false
};
