import React from 'react';
import PropTypes from 'prop-types';
import { Sites } from '@rvpower/constants';
import StarRatings from '../StarRatings';
import ProductDetailAccordion from './ProductDetailAccordion';

import getProviderLink from '../../js/project/provider-links';
import tracking from '../../js/project/tagular/tracking';

import iconCoin from '../../../images/icon-coin.svg';
import iconPapers from '../../../images/icon-papers.svg';
import iconPlug from '../../../images/icon-plug.svg';
import iconPDF from '../../../images/icon-pdf.svg';
import iconStar from '../../../images/icon-badge-star-default.svg';
import iconBBB from '../../../images/icon-bbb.svg';

function ProductDetailAccordions(props) {
  const { plan, site } = props;

  const rounded = (number) => Math.round(number * 2) / 2;
  const providerLink = getProviderLink(site, plan.supplier.name);
  const baseCharge = plan.rates.find((rate) => rate.type === 'BaseCharge')?.price;
  const energyCharge = (plan.rates.find((rate) => rate.type === 'EnergyCharge')?.price * 100)?.toFixed(1) || 0;
  // eslint-disable-next-line max-len
  const deliveryChargeKwh = (plan.rates.find((rate) => rate.type === 'DeliveryChargeKwh')?.price * 100)?.toFixed(1) || 0;
  // eslint-disable-next-line max-len
  const deliveryChargeMonthly = (plan.rates.find((rate) => rate.type === 'DeliveryChargeMonthly')?.price)?.toFixed(2) || 0;
  const hasBetterBusinessBadge = plan.badges.some((badge) => badge.name.includes('bbb-a-plus'));
  const overallRating = rounded(plan?.supplier?.ratings?.overallScore)?.toFixed(1) || '';

  const { rateTextOpened, rateTextClosed } = (function () {
    if (site === Sites.NEW_CTXP) {
      return {
        rateTextOpened: `We rate this provider ${plan?.supplier?.ratings?.overallScore}/5`,
        rateTextClosed: `We rate this provider ${plan?.supplier?.ratings?.overallScore}/5`
      };
    }

    return {
      rateTextOpened: `Rated ${plan?.supplier?.ratings?.overallScore}/5 by SaveOnEnergy`,
      rateTextClosed: `${plan?.supplier?.ratings?.overallScore}/5 by SaveOnEnergy`
    };
  }());

  const handleClickDocuments = (documentType) => {
    tracking.elementClicked({
      webElement: {
        location: 'Plan documents',
        elementType: 'Hyperlink',
        text: documentType
      },
      actionOutcome: 'Open plan details'
    });
  };

  const handleClickLearnMore = () => {
    tracking.elementClicked({
      webElement: {
        location: 'About partner modal',
        elementType: 'Hyperlink',
        text: 'Learn more'
      },
      actionOutcome: 'Redirects to partner page'
    });
  };

  return (
    <div className="pdp-accordions">
      <div className="pdp-accordions__inner">
        <ProductDetailAccordion
          title="Plan pricing"
          // eslint-disable-next-line max-len
          subtitle={<span>{`Base charge: $${baseCharge}/mo • Energy charge: ${energyCharge}¢ • Delivery charges: $${deliveryChargeMonthly}/mo, ${deliveryChargeKwh}¢/kWh`}</span>}
          icon={iconCoin}
        >
          <>
            <p className="pdp-accordion__copy">
              We share all the charges in your monthly bill to ensure transparency and prevent surprise fees.
              <span> You can review these charges in the provider&apos;s EFL.</span>
            </p>
            <div className="pdp-accordion__sections">
              <div className="pdp-accordion__section pdp-accordion__section--pricing-one">
                <div className="pdp-accordion__section-header">
                  <span className="pdp-accordion__section-title">Base charge</span>
                  <span className="pdp-accordion__section-price">
                    {`$${baseCharge}`}
                  </span>
                </div>
                <p className="pdp-accordion__section-copy">
                  This monthly amount covers operational costs and should stay the same during your contract.
                </p>
              </div>
              <div className="pdp-accordion__section pdp-accordion__section--pricing-two">
                <div className="pdp-accordion__section-header">
                  <span className="pdp-accordion__section-title">Energy charge</span>
                  <span className="pdp-accordion__section-price">
                    {`${energyCharge}¢`}
                  </span>
                </div>
                <p className="pdp-accordion__section-copy">
                  This is the default rate the provider charges for the plan.
                </p>
              </div>
              <div className="pdp-accordion__section pdp-accordion__section--pricing-three">
                <div className="pdp-accordion__section-header">
                  <span className="pdp-accordion__section-title">Delivery charges</span>
                  <div className="pdp-accordion__section-prices">
                    <span>
                      <strong>
                        {`$${deliveryChargeMonthly}`}
                      </strong>
                      /month
                    </span>
                    <span>
                      <strong>
                        {`${deliveryChargeKwh}¢`}
                      </strong>
                      /kwh
                    </span>
                  </div>
                </div>
                <p className="pdp-accordion__section-copy">
                  {/* eslint-disable-next-line max-len */}
                  There are two charges the utility requires for delivering electricity and maintaining power lines — one is a fixed charge per billing cycle, and the other is a usage charge per kWh that you consume. Charges vary based on location and utility.
                </p>
              </div>
            </div>
            {plan?.fee?.amount && (
              <>
                <p className="pdp-accordion__legal-copy">
                  <strong>Early termination fee: </strong>
                  {`$${plan.fee.amount}`}
                </p>
                <p className="pdp-accordion__legal-subcopy">
                  (Note: This fee will not be charged if you end your contract early because you are moving out.)
                </p>
              </>
            )}
          </>
        </ProductDetailAccordion>
        <ProductDetailAccordion
          title="Plan documents"
          // eslint-disable-next-line max-len
          subtitle={
            (
              <>
                <a
                  href={plan?.EFLDoc?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => handleClickDocuments('EFL')}
                >
                  Electricity facts label (EFL)
                </a>
                <a
                  href={plan?.TermsDoc?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => handleClickDocuments('Terms')}
                >
                  Terms of Service (TOS)
                </a>
                <a
                  href={plan?.YRACDoc?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => handleClickDocuments('YRAC')}
                >
                  Your Rights As a Consumer (YRAC)
                </a>
              </>
            )
          }
          icon={iconPapers}
        >
          <>
            <div className="pdp-accordion__sections pdp-accordion__sections--single-line">
              <div className="pdp-accordion__section pdp-accordion__section--document-one">
                <div className="pdp-accordion__section-header">
                  <span className="pdp-accordion__section-title">
                    <img src={iconPDF} alt="Document Icon" />
                    <a
                      href={plan?.EFLDoc?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => handleClickDocuments('EFL')}
                    >
                      Electricity Facts Label (EFL)
                    </a>
                  </span>
                </div>
                <p className="pdp-accordion__section-copy">
                  {/* eslint-disable-next-line max-len */}
                  An EFL breaks down plan info in more detail to understand a plan’s energy rate, fees, terms and conditions and more.
                </p>
              </div>
              <div className="pdp-accordion__section pdp-accordion__section--document-two">
                <div className="pdp-accordion__section-header">
                  <span className="pdp-accordion__section-title">
                    <img src={iconPDF} alt="Document Icon" />
                    <a
                      href={plan?.TermsDoc?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => handleClickDocuments('Terms')}
                    >
                      Terms of Service (TOS)
                    </a>
                  </span>
                </div>
                <p className="pdp-accordion__section-copy">
                  The TOS explains the terms and conditions of your electric service with the provider.
                </p>
              </div>
              <div className="pdp-accordion__section pdp-accordion__section--document-three">
                <div className="pdp-accordion__section-header">
                  <span className="pdp-accordion__section-title">
                    <img src={iconPDF} alt="Document Icon" />
                    <a
                      href={plan?.YRACDoc?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => handleClickDocuments('YRAC')}
                    >
                      Your Rights As a Consumer (YRAC)
                    </a>
                  </span>
                </div>
                <p className="pdp-accordion__section-copy">
                  YRAC protects your rights by detailing all the info you should know about dealing with the provider.
                </p>
              </div>
            </div>
          </>
        </ProductDetailAccordion>
        <ProductDetailAccordion
          title={`About ${plan?.supplier?.name}`}
          subtitle={
            (
              <>
                {plan?.supplier?.ratings && (
                  <>
                    <StarRatings rating={overallRating} totalStars={5} newGridEnabled />
                    <span>{rateTextClosed}</span>
                  </>
                )}
                {hasBetterBusinessBadge && (<span> • A+ BBB Rating</span>)}
              </>
            )
          }
          icon={iconPlug}
        >
          <>
            <p className="pdp-accordion__copy">
              {plan?.supplier?.whatToKnow}
              <a href={providerLink} target="_blank" rel="noopener noreferrer" onClick={handleClickLearnMore}>
                Learn more
              </a>
            </p>
            <div className="pdp-accordion__sections pdp-accordion__sections--single-line">
              {plan?.supplier?.ratings && (
                <div className="pdp-accordion__section pdp-accordion__section--rating">
                  <div className="pdp-accordion__section-header">
                    <span className="pdp-accordion__section-title">
                      <img className="pdp-accordion__section-icon" src={iconStar} alt="Rating Icon" />
                      {rateTextOpened}
                    </span>
                  </div>
                  <p className="pdp-accordion__section-copy">
                    We grade providers in three categories for their overall rating:
                  </p>
                  <div className="pdp-accordion__section-categories">
                    <span>
                      Customer service
                      <strong>
                        {`${plan?.supplier?.ratings?.customerService}/5`}
                      </strong>
                    </span>
                    <span>
                      Online accessibility
                      <strong>
                        {`${plan?.supplier?.ratings?.onlineAccessibility}/5`}
                      </strong>
                    </span>
                    <span>
                      Plan Options
                      <strong>
                        {`${plan?.supplier?.ratings?.planOptions}/5`}
                      </strong>
                    </span>
                  </div>
                </div>
              )}
              {hasBetterBusinessBadge && (
                <div className="pdp-accordion__section pdp-accordion__section--bbb">
                  <div className="pdp-accordion__section-header">
                    <span className="pdp-accordion__section-title">
                      <img className="pdp-accordion__section-icon" src={iconBBB} alt="BBB Icon" />
                      A+ BBB rating
                    </span>
                  </div>
                  <p className="pdp-accordion__section-copy">
                    The Better Business Bureau (BBB) rates businesses on how likely they are to interact with customers.
                  </p>
                </div>
              )}
            </div>
          </>
        </ProductDetailAccordion>
      </div>
    </div>
  );
}

export default ProductDetailAccordions;

ProductDetailAccordions.propTypes = {
  plan: PropTypes.shape({}).isRequired,
};
