import React, { useState } from 'react';
import PropTypes from 'prop-types';
import buildClassList from '../../../../js/project/buildClassList';
import tracking from '../../../../js/project/tagular/tracking';

import Checkbox from '../../checkbox';
import CheckboxMessaging from './CheckboxMessaging';

const Providers = ({
  className,
  filters,
  isNTX,
  plans,
  providers,
  setFilters,
  showAll
}) => {
  const [isCheckboxHidden, setIsCheckboxHidden] = useState(false);
  const providersCheckboxes = providers.map((p) => ({
    name: p.name,
    value: p.id,
    label: p.name,
    checked: filters.providers.includes(p.id),
    number: plans.filter((plan) => plan.supplier.id === p.id).length
  }));

  const shownProviders = [
    '4Change Energy',
    'Constellation',
    'Direct Energy',
    'Reliant',
    'TXU Energy',
    'Payless Power'
  ];

  const addFilter = (filterType) => (filterValue) => {
    setFilters({
      ...filters,
      [filterType]: [...filters[filterType], filterValue]
    });

    tracking.elementClicked({
      webElement: {
        elementType: 'CHECKBOX',
        location: 'PROVIDERS FILTER',
        name: 'FILTER DROPDOWNS',
        position: '3',
        text: `${filterType}: ${filterValue}`
      },
      actionOutcome: 'FILTER LIST'
    });
  };

  const removeFilter = (filterType) => (filterValue) => {
    setFilters({
      ...filters,
      [filterType]: filters[filterType].filter((f) => f !== filterValue)
    });

    tracking.elementClicked({
      webElement: {
        elementType: 'CHECKBOX',
        location: 'PROVIDERS FILTER',
        name: 'FILTER DROPDOWNS',
        position: '3',
        text: `${filterType}: ${filterValue}`
      },
      actionOutcome: 'FILTER LIST'
    });
  };

  const checkboxSections = {
    sectionLabel: 'Providers',
    checkboxes: providersCheckboxes,
    addFilter: addFilter('providers'),
    removeFilter: removeFilter('providers')
  };

  const classList = buildClassList('grid-v2-providers', className);

  return (
    <div className={classList}>
      {checkboxSections?.checkboxes.map((checkbox) => {
        // do we need the isNTX checks in this area?
        const showCheckbox = (shownProviders.includes(checkbox?.name) || isNTX || isCheckboxHidden || showAll);
        return (
          <Checkbox
            key={checkbox.name}
            checkbox={checkbox}
            className={showCheckbox ? 'u-display-flex' : 'u-display-none'}
            addFilter={checkboxSections.addFilter}
            removeFilter={checkboxSections.removeFilter}
          />
        );
      })}
      {!showAll && (
        <button
          type="button"
          className="grid-v2-providers__button"
          onClick={() => setIsCheckboxHidden(!isCheckboxHidden)}
        >
          <CheckboxMessaging isHidden={isCheckboxHidden} />
        </button>
      )}
    </div>
  );
};

export default Providers;

Providers.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    customPlanOrder: PropTypes.array.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    features: PropTypes.array.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    providers: PropTypes.array.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    rateTypes: PropTypes.array.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    ratings: PropTypes.array.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    terms: PropTypes.array.isRequired,
    usage: PropTypes.number.isRequired
  }).isRequired,
  isNTX: PropTypes.bool.isRequired,
  plans: PropTypes.arrayOf(PropTypes.shape({
    supplier: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  })).isRequired,
  providers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,
  setFilters: PropTypes.func.isRequired,
  showAll: PropTypes.bool
};

Providers.defaultProps = {
  className: '',
  showAll: false
};
