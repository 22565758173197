import React, { useState, useEffect, useRef } from 'react';
import Autocomplete from 'react-autocomplete';

import iconPin from '../../svg/icon-pin.svg';

const apiUrl = process.env.API_URL;
const appsyncUrl = process.env.APPSYNC_URL;
const getTokenEndpoint = `${apiUrl}/session/read-only?source=ac87aced-3761-402f-af86-98de0a658e81`;

const esiidQuery = `query address($addr: String!) {
    esiidAddress(address: $addr) {
        esiid
        address
        city
        state
        zipCode
        utilityId
    }
}`;

const esiidAddressQuery = `query esiidQuery($esiid: ID!) {
  esiid(esiid: $esiid) {
      address
      city
      esiid
      meterType
      premiseType
      state
      status
      switchHold
      utilityId
      zipCode
  }
}`;

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

// eslint-disable-next-line consistent-return
const getEsiidAddresses = async (address) => {
  const variables = { addr: address };

  try {
    const resp = await fetch(`${getTokenEndpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const token = await resp.json();
    if (token) {
      const response = await fetch(`${appsyncUrl}`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: token.token,
        },

        body: JSON.stringify({
          query: esiidQuery,
          variables,
        }),
      });

      const data = await response.json();

      return data;
    }
  } catch (error) {
    return error;
  }
};

// eslint-disable-next-line consistent-return
const getAddressFromEsiid = async (esiid, setInputValue) => {
  const variables = { esiid };

  try {
    const resp = await fetch(`${getTokenEndpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const token = await resp.json();
    if (token) {
      const response = await fetch(`${appsyncUrl}`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: token.token,
        },

        body: JSON.stringify({
          query: esiidAddressQuery,
          variables,
        }),
      });

      const data = await response.json();
      const addressData = data?.data?.esiid;

      if (addressData) {
        const fullAddress = `${addressData.address}, ${addressData.city}, ${addressData.state} ${addressData.zipCode}`;

        localStorage.setItem('fullAddress', fullAddress);
        setInputValue(fullAddress);
      }
      return data;
    }
  } catch (error) {
    return error;
  }
};

const EsiidAddressLookup = ({
  plansCount, placeholder = 'Enter address',
}) => {
  const [inputValue, setInputValue] = useState('');
  const [items, setItems] = useState([]);
  const debouncedInputValue = useDebounce(inputValue, 500) || '';

  const inputRef = useRef(null);

  // eslint-disable-next-line max-len
  const formatAddress = (address) => `${address.address}, ${address.city}, ${address.state.toUpperCase()} ${address.zipCode}`;

  const renderMenu = (menuItems) => {
    if (menuItems) {
      return <ul>{menuItems}</ul>;
    }
    return [];
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const onSelect = (_, val) => {
    const fullAddress = formatAddress(val);
    setInputValue(fullAddress);
    localStorage.setItem('esiid', val.esiid);
    localStorage.setItem('fullAddress', fullAddress);
  };

  const onSubmit = () => {
    const esiid = localStorage.getItem('esiid');

    if (items.length && esiid) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('esiid', esiid);
      window.location.search = searchParams.toString();
    }
  };

  const handleEsiidLookup = async (val, setLocalStorage = false) => {
    if (val.length > 3 && (debouncedInputValue || setLocalStorage)) {
      const data = await getEsiidAddresses(val);

      const formatData = Object.keys(data).map((key) => data[key]);

      setItems(formatData[0].esiidAddress);

      if (setLocalStorage && formatData[0].esiidAddress[0].address) {
        localStorage.setItem('fullAddress', formatData[0].esiidAddress[0].address);
      }
    }
  };

  useEffect(() => {
    handleEsiidLookup(inputValue);
  }, [debouncedInputValue, inputValue]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const ESIID = params.get('esiid');

    if (ESIID) {
      getAddressFromEsiid(ESIID, setInputValue);
    }

    const storedAddress = localStorage.getItem('fullAddress');

    if (storedAddress) {
      setInputValue(storedAddress);
    }
  }, []);

  return (
    <div
      className="esiid-address-lookup"
      ref={inputRef}
    >
      <span className="esiid-address-lookup__copy">
        We found
        <strong>
          {` ${plansCount} plans `}
        </strong>
        for
      </span>
      <div className="esiid-address-lookup__input-area">
        <div className="esiid-address-lookup__container">
          <img
            className="esiid-address-lookup__icon"
            src={iconPin}
            alt="Address"
          />
          <Autocomplete
            getItemValue={(item) => item.address}
            inputProps={{
              placeholder,
              className: 'esiid-address-lookup__input',
            }}
            items={items || []}
            onChange={handleChange}
            onSelect={onSelect}
            renderItem={(item, isHighlighted) => (
              <li
                className="esiid-address-lookup__list-item"
                key={item.esiid}
                style={{ background: isHighlighted ? '#F2F1F1' : '#FFFFFF' }}
              >
                {formatAddress(item).toUpperCase()}
              </li>
            )}
            renderMenu={renderMenu}
            value={inputValue}
            wrapperStyle={{ border: 'none' }}
          />
        </div>
        <button
          className={
            `esiid-address-lookup__submit
            ${items.length ? ' esiid-address-lookup__submit--focused' : ''}`
          }
          type="submit"
          onClick={onSubmit}
        >
          Go
        </button>
      </div>
    </div>
  );
};

export default EsiidAddressLookup;
