import React from 'react';
import PropTypes from 'prop-types';

import { FeaturedCardLabels } from '@rvpower/constants';

// SVGs
import apartmentIcon from '../../../svg/1000-icon.svg';
import smallHouseIcon from '../../../svg/1500-icon.svg';
import largeHouseIcon from '../../../svg/2000-icon.svg';
import creditIcon from '../../../svg/no-credit-check.svg';
import billIcon from '../../../svg/ico-bill-credit.svg';
import freenightsIcon from '../../../svg/ico-free-nights.svg';
import freeweekendsIcon from '../../../svg/ico-free-weekends.svg';
import greatvalueIcon from '../../../svg/ico-great-value.svg';
import moversIcon from '../../../svg/ico-our-mover-special.svg';
import nodepositIcon from '../../../svg/ico-no-deposit.svg';
import nosuprisesIcon from '../../../svg/ico-no-surprises.svg';
import popularIcon from '../../../svg/ico-popular-plan.svg';
import earthIcon from '../../../svg/ico-save-the-planet.svg';
import seasonalIcon from '../../../svg/ico-seasonal-discount.svg';
import instantApproval from '../../../svg/ico-instant-approval.svg';
import highCustomerSatisfaction from '../../../svg/ico-customer-satisfaction.svg';
import popularProvider from '../../../svg/ico-popular-provider.svg';
import lowEnergyCharge from '../../../svg/ico-low-energy-charge.svg';
import popularPlan from '../../../svg/ico-popular-plan-v2.svg';
import greenEnergy from '../../../svg/ico-green-energy.svg';
import competitiveRate from '../../../svg/ico-competitive-rate.svg';
import acProtection from '../../../svg/ico-ac-protection.svg';
import flatEnergyRate from '../../../svg/ico-flat-energy-rate.svg';
import longerPriceGuarantee from '../../../svg/ico-longer-price-guarantee.svg';
import longerPriceSecurity from '../../../svg/ico-longer-price-security.svg';
import noGimmicks from '../../../svg/ico-no-gimmicks.svg';
import renewableLeaf from '../../../svg/ico-renewable-leaf.svg';
import renewableRecycle from '../../../svg/ico-renewable-recycle.svg';
import popularForMoving from '../../../svg/ico-popular-for-moving.svg';
import popularForSwitching from '../../../svg/ico-popular-for-switching.svg';

import giftCardIcon from '../../../svg/ico-giftcard.svg';
import cashBackIcon from '../../../svg/ico-cash-back.svg';
import smartHomeEquipmentIcon from '../../../svg/ico-smarthome-equipment.svg';

// Maybe place this in the rv-power-constants?
const gridSvgOptions = {
  1: apartmentIcon,
  2: smallHouseIcon,
  3: largeHouseIcon,
  4: creditIcon,
  5: nodepositIcon,
  6: earthIcon,
  7: popularIcon,
  8: greatvalueIcon,
  9: nosuprisesIcon,
  10: freenightsIcon,
  11: freeweekendsIcon,
  12: billIcon,
  13: seasonalIcon,
  14: moversIcon,
  15: freeweekendsIcon,
  16: billIcon,
  17: popularIcon,
  18: billIcon,
  19: giftCardIcon,
  20: cashBackIcon,
  21: smartHomeEquipmentIcon,
  22: largeHouseIcon, // icon re-purposed for "great for work from home"
  23: instantApproval,
  24: highCustomerSatisfaction,
  25: popularProvider,
  26: lowEnergyCharge,
  27: popularPlan,
  28: greenEnergy,
  29: competitiveRate,
  30: acProtection,
  31: flatEnergyRate,
  32: longerPriceGuarantee,
  33: longerPriceSecurity,
  34: noGimmicks,
  35: renewableLeaf,
  36: renewableRecycle,
  37: popularForMoving,
  38: popularForSwitching
};

/**
 * @typedef {Object} LabelAndIconObj
 * @property {string} icon - The icon SVG string
 * @property {string} text - The product text that goes with the SVG
 */

/**
 *
 * @param {number} id - the product id
 * @returns {LabelAndIconObj}
 */
const buildLabelAndIconObj = (id) => {
  // set plan default if no id
  if (!id) {
    return {
      icon: nosuprisesIcon,
      text: FeaturedCardLabels[8].label,
    };
  }

  // ported over,
  // could we potentially just change the icon/text in the site constants?
  if (id === 35 || id === 36) {
    return {
      icon: nosuprisesIcon,
      text: '100% Renewable',
    };
  }

  // default behavior
  return {
    icon: gridSvgOptions[id],
    text: FeaturedCardLabels[id - 1].label,
  };
};

const FeatureCardLabel = ({
  plan
}) => {
  const { labelId } = plan;
  const labelAndIcon = buildLabelAndIconObj(labelId);

  return (
    <div
      className="featured-card__section label u-display-flex u-align-items-center"
    >
      <img
        className="svg svg--featured-card-icon"
        src={labelAndIcon.icon}
        alt={labelAndIcon.text}
      />
      <span className="label__text">
        {labelAndIcon.text}
      </span>
    </div>
  );
};

FeatureCardLabel.propTypes = {
  plan: PropTypes.shape({
    labelId: PropTypes.number
  }).isRequired
};

export default FeatureCardLabel;
