// This component was created for the desktop version of the Price to Compare Savings Across Banner design
// It should only show if the ntxP2CSavingsAcrossPrice flag is true - this flag is set in Monarch

/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import tracking from '../js/project/tagular/tracking';
import NTXCardBadges from './cards/sub-components/NTXCardBadges';
import NTXPlanDetails from './grid-v2/NTXPlanDetails';

// SVGs
import detailArrowSolid from '../svg/detail-arrow-solid-green-down.svg';

function P2CSavingsAcrossPrice({
  plan,
  site,
  disableCartLinks,
  onOrderOnline
}) {
  const { name } = plan;
  const { price } = plan.rates.find((rate) => rate.type === 'EnergyCharge');
  const hasPrice = plan.priceToComparePercentage !== null && plan.priceToComparePercentage !== 0;

  const planTermText = plan.termText
    .replace(/Months/g, 'mos.')
    .replace(/Month to Month/g, 'Monthly');
  const planPricePerUnit = price ? (price * 100).toFixed(2) : '0';
  const planType = plan.type.toUpperCase();
  const unitOfMeasure = Object.keys(plan.unitOfMeasure).length
    ? plan.unitOfMeasure.description
    : plan.unitOfMeasure;

  // Filter out supplier and plan badges
  const supplierBadges = plan.badges.filter((badge) => {
    if (badge.name.includes('gas') || badge.name.includes('electric')) {
      return badge;
    }
    return null;
  });

  const planBadgesFiltered = plan.badges.filter((badge) => {
    if (!badge.name.includes('gas') && !badge.name.includes('electric')) {
      return badge;
    }
    return null;
  });

  const hasBadges = !!plan.badges.length;

  let planUnit = unitOfMeasure?.toUpperCase();
  if (planUnit?.includes('THM')) planUnit = 'THERM';
  if (planUnit?.length === 0 || planUnit === null) planUnit = 'KWH';

  // const [isHidden, setIsHidden] = useState(true);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const showCartCTA = plan.cartActive
       && !disableCartLinks
       && !plan?.supplier?.controls?.disableCart;
  const showPhoneCTA = plan?.supplier?.phone?.length > 0;
  const ctaURL = plan.cartLink;
  const supplierPhone = plan.supplier.phone;

  const handleOpenDetails = (detailsOpened) => {
    setDetailsOpen(detailsOpened);

    tracking.elementClicked({
      webElement: {
        location: 'GRID',
        elementType: 'Button',
        text: 'More Details'
      },
      actionOutcome: 'Plan details expanded'
    });

    // Lock mobile viewport to prevent background from scrolling
    if (detailsOpen) document.body.classList.remove('grid-v2__mobile-locked');
    else document.body.classList.add('grid-v2__mobile-locked');
  };

  // Ensure all phone numbers are formatted like 1-888-888-8888
  // Assumes all phone numbers will come back either as 888-888-8888 or 1-888-888-8888
  const formatSupplierPhone = (num) => {
    const firstTwoNumbers = num.substring(0, 2);

    if (firstTwoNumbers !== '1-') {
      const newSupplierNumber = `1-${num}`;
      return newSupplierNumber;
    }
    return num;
  };

  return (
    <div className="plan-card-v2__top-large ntx ntx-savings-across-price">
      <div className="plan-card-v2__p2c-savings-across-price">
        <div className="plan-card-v2__name-container plan-card-v2__data-compartment">
          {/* Supplier Badge */}
          <NTXCardBadges
            badges={hasBadges ? supplierBadges : null}
            featuredCard={false}
            supplierBadges
          />
          {' '}
          {/* Plan Info */}
          {/* Title */}
          <div className="plan-card-v2__title ntx">{name}</div>
          {/* Logo */}
          <div className="plan-card-v2__logo--ntx">
            <img
              className="plan-card-v2__logo"
              id="ntx-plan-card-logo-img"
              src={plan.supplier.logo}
              alt={plan.supplier.name}
            />
          </div>
          <button
            // eslint-disable-next-line max-len
            className={`button--details u-display-flex u-align-items-center ${
              detailsOpen ? 'button--open' : ''
            }`}
            id="ntx-plan-details-button"
            type="submit"
            onClick={() => handleOpenDetails(!detailsOpen)}
          >
            <span>More Details</span>
            <img
              className={`svg svg--detail-arrow ${
                detailsOpen ? 'details-is-shown' : ''
              }`}
              src={detailArrowSolid}
              alt="Arrow"
            />
          </button>
        </div>
        {/* Plan Badges */}
        <div className="plan-card-v2__flex-container plan-card-v2__border plan-card-v2__data-compartment">
          <NTXCardBadges
            badges={planBadgesFiltered}
            featuredCard={false}
            planType={planType}
          />
        </div>
        {/* Price per Unit & Term Length */}
        <div className="plan-card-v2__price-term-label plan-card-v2__flex-container plan-card-v2__data-compartment">
          <div className="plan-card-v2__flex-container-text">
            <p className="plan-card-v2__price-label ntx">Term length</p>
            <p className="plan-card-v2__price-label-secondary ntx">{`${planType} RATE`}</p>
            <strong className="plan-card-v2__price-value ntx">
              {planTermText}
            </strong>
          </div>
        </div>
        <div
          className={`plan-card-v2__price-term-label plan-card-v2__p2c-container  plan-card-v2__data-compartment ${
            hasPrice ? 'add-price' : 'no-price'
          }`}
        >
          {hasPrice && (
            <div className="plan-card-v2__ntx--p2c-savings-across-price-banner">
              <span className="percentage">
                {plan.priceToComparePercentage}
                %
              </span>
              {' '}
              <span className="savings-text">est. savings**</span>
            </div>
          )}

          <div className="plan-card-v2__flex-container-text">
            <p className="plan-card-v2__price-label ntx">Price per</p>
            <p className="plan-card-v2__price-label-secondary ntx">{`${planUnit}`}</p>
            <strong className="plan-card-v2__price-value ntx">{`${planPricePerUnit}¢`}</strong>
          </div>
        </div>
        <div
          className={`plan-card-v2__cta-container-medium ntx ntx--details-container ${
            detailsOpen ? ' plan-card-v2__cta-container-medium--open' : ''
          }`}
        >
          <a
            className={`plan-card-v2__cta plan-card-v2__cta--cart ${
              !showPhoneCTA ? 'ntx-center-cartCTA' : ''
            }`}
            style={!showCartCTA ? { display: 'none' } : {}}
            href={ctaURL}
            id="ntx-cart-cta"
            target="_blank"
            onClick={(e) => onOrderOnline(e, ctaURL, plan)}
          >
            Check availability
          </a>
          <div
            className="button--phone u-display-flex@lg u-justify-content-center@lg"
          >
            <a
              className={`plan-card-v2__cta phone--with-icon ${
                !showCartCTA ? 'ntx-center-fuse' : ''
              }`}
              style={!showPhoneCTA ? { visibility: 'hidden' } : {}}
              id="ntx-phone-cta"
              href={`tel:${formatSupplierPhone(supplierPhone)}`}
            >
              <span className="ntx-phone-number-cta">
                {formatSupplierPhone(supplierPhone)}
              </span>
            </a>
          </div>
        </div>
      </div>
      {detailsOpen && (
        <NTXPlanDetails
          additionalClasses="p2c-plan-details"
          plan={plan}
          site={site}
          featured={false}
        />
      )}
    </div>
  );
}

P2CSavingsAcrossPrice.propTypes = {
  plan: PropTypes.shape({}).isRequired,
};

export default P2CSavingsAcrossPrice;
