/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { useApolloClient } from '@apollo/react-hooks';
import { UsageAmounts } from '@rvpower/constants';
import { usePreamp } from '../preamp';
import { useMonarch } from '../monarch';

// React Components
import PlanCardsWidgetWrapper from './PlanCardsWidgetWrapper';
import Loader from '../Loader';
import Error from '../Error';
import MaintenanceMode from '../MaintenanceMode';
import { FuseProvider } from '../FuseNumbers';
import { useOutsideGridUtilities } from '../../js/hooks/useOutsideGridUtilities';

// Get plan data by plan id
const getPlanQuery = () => {
  const query = `
  query (
    $planId: ID!,
    $utilityId: ID!,
    $usage: [Int],
  ) {
    plan(
      planId: $planId,
      utilityId: $utilityId,
      usage: $usage
    ) {
      id
      description
      secondaryDescription
      documents {
        type
        url
      }
      name
      unitOfMeasure
      supplier {
        id
        isPartner
        logo
        name
        logo
        parentId
        phone
        checkoutContent
        allowAutoBillPay
        allowDeposit
        allowEBilling

        ratings {
          customerService
          onlineAccessibility
          businessHistory
          overallScore
          planOptions
        }
      }
      rates {
        price
        type
      }
      term {
        length
        type
      }
      type
      fees {
        type
        amount
        monthly
      }
      utility {
        name
        id
        phone
        loginUrl
      }
      badges {
        id
        name
        tooltip
        source
      }
      features
      cartActive
      phoneActive
      active
      priceCodeId
      pricePerKwh
    }
  }`;

  return gql`${query}`;
};

// Uses config data to append labelId and usageId to plan data
const getPlans = (plansById, configData) => configData.map((plan) => (
  {
    ...plansById[plan.planId],
    labelId: parseInt(plan.labelId, 10),
    usageId: plan.usageId
  }
)).filter((plan) => plan.name);

const PlanCardsWidgetQuery = (props) => {
  const { rules } = useMonarch();

  const {
    site, fuseData, externalGrid = false
  } = props;
  const [editedPlans, setEditedPlans] = useState([]);
  const params = new URLSearchParams(window.location.search);
  const configParam = params.get('config');

  const preampConfig = usePreamp('Rates Outside of Grid - Config');
  const monarchConfig = rules?.source?.rogConfig?.value?.data?.rogConfig;
  const rogConfigId = preampConfig || monarchConfig || 'default';

  const [zipCode, setZipCode] = useState(params.get('zipCode') || sessionStorage.getItem('zipCode') || '75204');
  const client = useApolloClient();
  const [getUtilities, { data = { siteSettings: {} }, error, called }] = useOutsideGridUtilities(zipCode, site);
  const { disablePhoneNumbers } = data.siteSettings;
  const [loading, setLoading] = useState(true);
  const [noUtitlities, setNoUtilities] = useState(false);

  const theme = {
    'new.choosetexaspower.org': 'ctxp',
    'new.texaselectricrates.com': 'txer',
    'saveonenergy.com': 'soe',
  };

  const themeBuilder = (themeName) => {
    const grid = document.querySelector('#js-grid');

    if (!grid) return;

    if (grid.dataset.plansWidgetType === 'editorial') {
      grid.classList.add('editorial');
    }

    grid.classList.add(`theme-${themeName}`);
  };

  const formatConfigName = (name) => name.toLowerCase().replaceAll(' ', '-');

  // Match config name from query param to config from power admin
  const getConfig = (configs = [], id) => (
    configs.find((c) => formatConfigName(c.name) === id) || configs.find((f) => f.name === 'default')
  );

  const modalObserver = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.contentRect.height && window.parent) {
        window.parent.postMessage(
          { type: 'widget-resize', height: `${entry.contentRect.height}px` },
          '*'
        );
      }
    });
  });

  // Gets plan data for all plan ids
  const handleMultiplePlanQueries = async (planIds, utilityId) => {
    const promises = planIds.map((planId) => client.query({
      query: getPlanQuery(false),
      variables: {
        planId,
        utilityId,
        usage: 1000,
      },
    }));

    const planData = await Promise.all(promises);

    return planData.map((plan) => plan?.data?.plan);
  };

  const getEditedPlans = async () => {
    setLoading(true);
    const config = getConfig(data.outsideGridFeaturedConfigs || [], rogConfigId || configParam);
    const selectedUtility = data.utilities[0].id;
    // Gets correct config data by utility id
    const {
      featuredPlans: configData = []
    } = config?.utilities.find((util) => util.utilityId === selectedUtility) || {};
    const planIds = configData.map((c) => c.planId);
    const planQueryData = planIds.length > 0 ? await handleMultiplePlanQueries(planIds, selectedUtility) : [];
    const editedPlansById = planQueryData.reduce((accum, curVal) => ({ ...accum, [curVal.id]: curVal }), {});
    const plans = getPlans(editedPlansById, configData);

    // Appending extra fields to plans that will be used throughout the components
    const editedPlansArr = plans.map((plan) => {
      const prices = {};
      const estimates = {};
      const usageLegend = {
        TwoThousandKwh: 2000,
        OneThousandKwh: 1000,
        FiveHundredKwh: 500
      };

      plan.rates.forEach((r) => {
        if (usageLegend[r.type]) {
          prices[usageLegend[r.type]] = r.price ? (parseFloat(r.price)) : 0;
          estimates[usageLegend[r.type]] = r.price ? (parseFloat(r.price) * usageLegend[r.type]).toFixed(0) : 0;
        }
      });

      plan.prices = prices;
      plan.estimates = estimates;

      const usageLabel = UsageAmounts[plan.usageId].label;
      const usageNum = usageLabel ? parseInt(usageLabel, 10) : 1;

      plan.price = plan.prices[usageNum];

      return plan;
    });

    setEditedPlans(editedPlansArr);
    setLoading(false);
  };

  const handleOnZipCodeSubmit = (zip) => {
    sessionStorage.setItem('zipCode', zip);
    setZipCode(zip);
    setLoading(true);
    getUtilities();
  };

  useEffect(() => {
    const modalWrapper = document.querySelector('#js-grid');
    if (modalWrapper) modalObserver.observe(modalWrapper);

    return () => {
      modalObserver.unobserve(modalWrapper);
    };
  }, []);

  // set the default selectedUtility if we only have utility provider
  useEffect(() => {
    if (data?.utilities?.length === 1) {
      getEditedPlans();
    }
  }, [data.utilities]);

  useEffect(() => {
    if (data?.utilities?.length === 0 && called) {
      sessionStorage.removeItem('zipCode');
      setNoUtilities(true);
      setLoading(false);
    }
  }, [called, data.utilities]);

  useEffect(() => {
    themeBuilder(theme[site]);
    getUtilities();
  }, []);

  if (data?.siteSettings?.maintenanceMode) return <MaintenanceMode site={site} fuseData={fuseData} />;
  if (error) return <Error error={error} fuseData={fuseData} />;

  return (
    <>
      {editedPlans.length ? (
        <FuseProvider
          fuseData={fuseData}
          isOpen={data.isCallCenterOpen}
          isTxu={false}
          disablePhoneNumbers={disablePhoneNumbers}
        >
          {loading && <Loader />}
          {!loading && (
            <PlanCardsWidgetWrapper
              plans={editedPlans}
              fuseData={fuseData}
              site={site}
              onSubmit={handleOnZipCodeSubmit}
              zipCode={zipCode}
              noUtitlities={noUtitlities}
              externalGrid={externalGrid}
            />
          )}
        </FuseProvider>
      ) : null}

      {(loading && !editedPlans.length) && <Loader />}
      {(!loading && !editedPlans.length) && (
        <PlanCardsWidgetWrapper
          plans={editedPlans}
          fuseData={fuseData}
          site={site}
          onSubmit={handleOnZipCodeSubmit}
          zipCode={zipCode}
          noUtitlities={noUtitlities}
          externalGrid={externalGrid}
        />
      )}
    </>
  );
};
export default PlanCardsWidgetQuery;
