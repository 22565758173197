import React, { useState } from 'react';
import beam from '../../js/project/tagular/beam';

const EmailCapture = () => {
  const [emailIsValid, setEmailIsValid] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // eslint-disable-next-line max-len
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleChange = (event) => {
    const emailAddress = event.target.value;
    const valid = emailRegex.test(emailAddress);
    setEmailIsValid(valid);
  };

  const handleSubmit = () => (event) => {
    event.preventDefault();
    const userEmail = document.querySelector('.unserve-email__subscribe-form-input').value;

    if (emailIsValid) {
      setIsSubmitted(true);

      const formSubmitedPayload = {
        field: [
          {
            fieldType: 'Grid Email Capture',
            fieldName: 'Email Address',
            fieldValue: userEmail,
          },
        ]
      };
      beam.userTrackingV3('FormSubmitted', formSubmitedPayload);

      const elementClickedPayload = {
        webElement:
          {
            elementType: 'Button',
            text: 'Subscribe',
            location: 'UnservGrid',
          },
        actionOutcome: 'Email Submitted'
      };
      beam.userTrackingV3('ElementClicked', elementClickedPayload);
    } else {
      setEmailIsValid(false);
    }
  };

  const inputClickHandler = () => {
    const payload = {
      webElement:
        {
          elementType: 'Form',
          location: 'UnservGrid',
        },
      actionOutcome: 'Email Form Engaged'
    };

    beam.userTrackingV3('ElementClicked', payload);
  };

  return (
    <div id="subscribe" className="unserve-email">
      {!isSubmitted ? (
        <div className="unserve-email__subscribe">
          <h2 className="unserve-email__subscribe-heading">
            Subscribe to learn when more products are available near you
          </h2>
          <form onSubmit={handleSubmit()} className="unserve-email__subscribe-form">
            <div className="unserve-email__subscribe-form-input-wrapper">
              <input
                type="email"
                placeholder="Email address"
                className="unserve-email__subscribe-form-input"
                onChange={(e) => handleChange(e)}
                onClick={inputClickHandler}
              />
              {emailIsValid === false && (
              <p className="unserve-email__subscribe-form-invalid">
                Email must be a valid email address
              </p>
              )}
            </div>
            <button type="submit" className="unserve-email__subscribe-form-submit">Subscribe</button>
          </form>
          <p className="unserve-email__subscribe-fine-print">
            By subscribing, you agree to receive SaveOnEnergy promotional emails. Your
            {' '}
            <a
              href="https://www.saveonenergy.com/privacy/"
              target="blank"
              className="unserve-email__subscribe-fine-print-link"
            >
              privacy
            </a>
            {' '}
            is important to us.
          </p>
        </div>
      ) : (
        <div className="unserve-email__confirmed">
          <h2 className="unserve-email__confirmed-thanks">Thanks for subscribing!</h2>
          <p className="unserve-email__confirmed-subtext">
            We&rsquo;ll send the latest news and deals directly to your inbox.
          </p>
        </div>
      )}
    </div>
  );
};

export default EmailCapture;
