import React, { useState, useEffect } from 'react';
import beam from '../../js/project/tagular/beam';

const SplashZipSearch = (props) => {
  const {
    zipCode,
  } = props;

  const [localZipCode, setLocalZipCode] = useState(zipCode);
  const [movingValue, setMovingValue] = useState('');

  const updateZip = () => {
    if (window.location.href.includes('sp')) {
      window.location.href = `/compare-offers/sp/?zipCode=${localZipCode}&m=${movingValue}`;
    }
  };

  const submit = (e) => {
    e.preventDefault();

    const payload = {
      field: [{
        fieldType: 'Splash Page Zip Form Submitted',
        fieldName: 'Zip Code',
        fieldValue: localZipCode,
      }],
      formContext: {
        formType: 'Zip Check',
        formName: 'radio-questions',
        formVersion: 'Homepage Hero Splash Page',
      }
    };

    beam.userTrackingV3('FormSubmitted', payload);
    localStorage.setItem('splashZipEntered', 'true');

    setTimeout(() => {
      updateZip();
    }, 500);
  };

  const handleChange = (e) => {
    setLocalZipCode(e.target.value);
  };

  const handleMove = (value) => {
    setMovingValue(value);
  };

  // Updates the zip in the middle of the grid page after the browser back button is clicked
  useEffect(() => {
    if (zipCode !== localZipCode) {
      setLocalZipCode(zipCode);
      updateZip();
    }
  }, []);

  return (
    <form onSubmit={(e) => submit(e)}>
      <div className="zip-search__form">
        <input
          id="zip-code"
          className="zip-search__input"
          value={localZipCode}
          onChange={(e) => handleChange(e)}
          type="text"
          inputMode="numeric"
          maxLength="5"
          pattern="[0-9]{5}"
          title="Please enter a valid zip code"
          required
          aria-label="zip code search"
          placeholder="Enter ZIP code"
        />
        <button
          className="zip-search__button"
          type="submit"
        >
          Explore
        </button>
      </div>
      <div className="moving">
        <p className="moving__heading">Are you moving to a new address?</p>
        <div className="moving-form">
          <label htmlFor="movey" className="moving-form__option">
            <input
              type="radio"
              id="movey"
              name="movingForm"
              value="movey"
              className="moving-form__radio-input"
              onClick={() => handleMove('movey')}
            />
            <span className="moving-form__radio-circle" />
            Yes
          </label>
          <label htmlFor="moven" className="moving-form__option">
            <input
              type="radio"
              id="moven"
              name="movingForm"
              value="moven"
              className="moving-form__radio-input"
              onClick={() => handleMove('moven')}
            />
            <span className="moving-form__radio-circle" />
            No
          </label>
        </div>
      </div>
    </form>
  );
};

export default SplashZipSearch;
