import React from 'react';
import PropTypes from 'prop-types';
import tracking from '../../../../js/project/tagular/tracking';

import CaledarFilled from '../../../../svg/calendar-filled.svg';
import CreditCardCheck from '../../../../svg/credit-card-check.svg';
import LeafFilled from '../../../../svg/leaf-filled.svg';
import VectorChecked from '../../../../svg/vector-checked.svg';

import Feature from './Feature';
import Checkbox from '../../checkbox';

const Features = ({
  filters,
  setFilters,
  isNTX
}) => {
  const addFilter = (filterType) => (filterValue) => {
    setFilters({
      ...filters,
      [filterType]: [...filters[filterType], filterValue]
    });

    tracking.elementClicked({
      webElement: {
        elementType: 'BUTTON',
        location: 'FEATURES FILTER',
        name: 'FILTER DROPDOWNS',
        position: '2',
        text: `${filterType}: ${filterValue}`
      },
      actionOutcome: 'FILTER LIST'
    });
  };

  const removeFilter = (filterType) => (filterValue) => {
    setFilters({
      ...filters,
      [filterType]: filters[filterType].filter((f) => f !== filterValue)
    });
  };

  const checkboxSections = {
    sectionLabel: 'Features',
    addFilter: addFilter('features'),
    removeFilter: removeFilter('features'),
    checkboxes: [
      {
        name: 'satsifaction-guarantee',
        value: 'SatisfactionGuarantee',
        label: 'Satisfaction guarantee',
        icon: VectorChecked,
        checked: filters.features.includes('SatisfactionGuarantee'),
      },
      {
        name: 'feature-green-energy',
        value: 'GreenEnergy',
        label: 'Green energy',
        icon: LeafFilled,
        checked: filters.features.includes('GreenEnergy'),
      },
      {
        name: 'feature-free-usage',
        value: 'FreeUsagePeriod',
        label: 'Free usage period',
        icon: CaledarFilled,
        checked: filters.features.includes('FreeUsagePeriod'),
      },
      {
        name: 'feature-no-credit-check',
        value: 'NoCreditCheck',
        label: 'All credit approved',
        icon: CreditCardCheck,
        checked: filters.features.includes('NoCreditCheck'),
      }
    ],
    ntxCheckboxes: [
      {
        name: 'low-cost-choice',
        value: 'Low-cost choice',
        label: 'Low-cost choice',
        checked: filters.features.includes('Low-cost choice'),
      },
      {
        name: 'renewable',
        value: 'Renewable',
        label: 'Renewable',
        checked: filters.features.includes('Renewable'),
      },
      {
        name: 'variable-rate',
        value: 'Variable rate',
        label: 'Variable rate',
        checked: filters.features.includes('Variable rate'),
      },
      {
        name: 'fixed-rate',
        value: 'Fixed rate',
        label: 'Fixed rate',
        checked: filters.features.includes('Fixed rate'),
      },
      {
        name: 'electric',
        value: 'Electric',
        label: 'Electric',
        checked: filters.features.includes('Electric'),
      },
      {
        name: 'natural-gas',
        value: 'Gas',
        label: 'Gas',
        checked: filters.features.includes('Gas'),
      }
    ]
  };

  const options = isNTX ? checkboxSections.ntxCheckboxes : checkboxSections.checkboxes;

  return (
    <div className={`grid-v2-features ${isNTX ? 'ntx' : ''}`}>
      {options.map((checkbox) => (
        <React.Fragment key={checkbox.name}>
          {
          isNTX ? (
            <Checkbox
              key={checkbox.name}
              checkbox={checkbox}
              className="u-display-flex"
              addFilter={checkboxSections.addFilter}
              removeFilter={checkboxSections.removeFilter}
            />
          ) : (
            <Feature
              key={checkbox.name}
              checkbox={checkbox}
              addFilter={checkboxSections.addFilter}
              removeFilter={checkboxSections.removeFilter}
            />
          )
         }
        </React.Fragment>
      ))}
    </div>
  );
};

export default Features;

Features.propTypes = {
  filters: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    customPlanOrder: PropTypes.array.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    features: PropTypes.array.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    providers: PropTypes.array.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    rateTypes: PropTypes.array.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    ratings: PropTypes.array.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    terms: PropTypes.array.isRequired,
    usage: PropTypes.number.isRequired
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
};
