import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import tracking from '../js/project/tagular/tracking';

// React Components
import CardDetails from './CardDetails';
import NTXPlanDetails from './grid-v2/NTXPlanDetails';

// SVGs
import detailArrow from '../svg/detail-arrow.svg';
import detailArrowSolid from '../svg/detail-arrow-solid-green-down.svg';

function CardDetailsTrigger(props) {
  const {
    plan,
    site,
    electricPhoenixActive,
    incentiveActive,
    isNTX,
    termText,
  } = props;
  const [isHidden, setIsHidden] = useState(true);

  const showHidePlanDetails = () => {
    if (isHidden) {
      tracking.elementClicked({
        webElement: {
          location: 'GRID',
          elementType: 'Button',
          text: 'Plan Details'
        },
        actionOutcome: 'Plan details expanded'
      });
    }

    if (!isHidden) {
      tracking.elementClicked({
        webElement: {
          location: 'GRID',
          elementType: 'Button',
          text: 'Plan Details'
        },
        actionOutcome: 'Plan details closed'
      });
    }

    setIsHidden(!isHidden);
  };

  const buttonText = isNTX ? 'More details' : 'Plan details';

  return (
    <div
      className={`card__section card__section--card-details-trigger
      ${isNTX ? 'card__section--card-details-trigger-ntx' : ''}`}
    >
      <div className="u-display-flex u-align-items-center u-justify-content-space-between">
        <button
          // eslint-disable-next-line max-len
          className={`button--details button--p2c-savings-across-price u-display-flex u-align-items-center ${!isHidden ? 'button--open' : ''}`}
          id={`${isNTX ? 'ntx-plan-details-button' : ''}`}
          type="submit"
          onClick={showHidePlanDetails}
        >
          <span>{buttonText}</span>
          <img
            className={`svg svg--detail-arrow ${!isHidden ? 'details-is-shown' : ''}`}
            src={`${isNTX ? detailArrowSolid : detailArrow}`}
            alt="Arrow"
          />
        </button>
        {/* Optional term text for certain designs */}
        {termText && !isNTX && (
          <p className="u-display-flex u-display-none@lg">
            <strong>
              {`TERM: ${termText.toUpperCase()}`}
            </strong>
          </p>
        )}
      </div>
      {/* eslint-disable-next-line max-len */}
      {!isHidden && (
        isNTX ? (
          <NTXPlanDetails
            plan={plan}
            site={site}
            featured={false}
          />
        ) : (
          <CardDetails
            plan={plan}
            site={site}
            electricPhoenixActive={electricPhoenixActive}
            incentiveActive={incentiveActive}
          />
        )
      )}
    </div>
  );
}

CardDetailsTrigger.propTypes = {
  electricPhoenixActive: PropTypes.bool,
  isNTX: PropTypes.bool,
  plan: PropTypes.shape({}).isRequired,
  site: PropTypes.string.isRequired,
  termText: PropTypes.string,
};

CardDetailsTrigger.defaultProps = {
  electricPhoenixActive: false,
  isNTX: false,
  termText: '',
};

export default CardDetailsTrigger;
