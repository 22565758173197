/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { UsageAmounts } from '@rvpower/constants';
import { usePreamp } from '../../preamp';
import { useMonarch } from '../../monarch';

import TXURedesignFeaturedCardVariant from './TXUFeaturedCardVariant';

const TXUFeaturedCard = ({
  featuredCard,
  onOrderOnline,
  plan,
  planDetailsToggle,
  showPlanDetails,
  site,
  dwellingType
}) => {
  const {
    description,
    name,
    type,
    incentives,
    badges,
    cartUrl,
    usageId
  } = plan;

  /** Incentives:
   * Check if home is a single or multi family to display incentive offer text */
  const incentiveOfferText = dwellingType === 'multi'
    ? incentives.multiFamilyIncentive.offerText
    : incentives.singleFamilyIncentive.offerText;
  const offerIncentivesPreamp = usePreamp('Offer Incentives');
  const { rules } = useMonarch();
  const TXUBadge = usePreamp('Txu badges') || rules?.source?.txuBadge?.value;
  const txuSimplifiedFeaturedCards = { option: 'option-1' };

  const incentiveActive = offerIncentivesPreamp && incentiveOfferText !== '';

  const { termText } = plan;

  const [usage, setUsage] = useState('1000');

  useEffect(() => {
    if (usageId && usageId !== '') {
      setUsage(UsageAmounts[usageId].label);
    }
  }, [usageId]);

  const featuredCardUrl = (cartUrlLink, usageAmount) => {
    return cartUrlLink.replace(/rateType=[0-9]*/, `rateType=${usageAmount}`);
  };

  return (
    <TXURedesignFeaturedCardVariant
      featuredCard={featuredCard}
      onOrderOnline={onOrderOnline}
      planDetailsToggle={planDetailsToggle}
      showPlanDetails={showPlanDetails}
      description={description}
      name={name}
      type={type}
      plan={plan}
      badges={badges}
      cartUrl={cartUrl}
      TXUBadge={TXUBadge}
      incentiveActive={incentiveActive}
      termText={termText}
      usage={usage}
      featuredCardUrl={featuredCardUrl}
      site={site}
      variant={txuSimplifiedFeaturedCards?.option}
    />
  );
};

TXUFeaturedCard.propTypes = {
  featuredCard: PropTypes.shape({}).isRequired,
  onOrderOnline: PropTypes.func.isRequired,
  plan: PropTypes.shape({
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    supplier: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired,
  planDetailsToggle: PropTypes.bool.isRequired,
  showPlanDetails: PropTypes.func.isRequired,
  site: PropTypes.string.isRequired,
  dwellingType: PropTypes.string.isRequired
};

export default TXUFeaturedCard;
