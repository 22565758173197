import React from 'react';

import HmcTextLoader from './HmcTextLoader';
import HmcCircleLoader from './HmcCircleLoader';

import homeIcon from '../../../images/home-icon.svg';

const HmcLoader = () => (
  <div className="hmc-loader">
    <div className="hmc-loader__heading">
      <img
        src={homeIcon}
        alt="home icon"
        className="hmc-loader__heading-home-icon"
      />
      <p className="hmc-loader__heading-text">
        Gathering plans
        <br />
        for your home
      </p>
    </div>
    <HmcTextLoader />
    <HmcCircleLoader />
  </div>
);

export default HmcLoader;
