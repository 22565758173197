import React from 'react';
import PropTypes from 'prop-types';

import CardDetailsTrigger from '../../CardDetailsTrigger';
import EpCardBillAmount from '../../electric-phoenix/EpCardBillAmount';
import Cta from '../sub-components/Cta';
import Ratings from '../../Ratings';

function EPCard({
  plan,
  planIndex,
  site,
  onOrderOnline,
  electricPhoenixActive,
  electricPhoenixToggleActive,
  electricPhoenixInput,
  epData,
  disableCartLinks
}) {
  const {
    termText,
    type,
    supplier,
    name,
  } = plan;

  let EPAnnualSavings = 0;
  if (electricPhoenixActive && epData && epData.annualCost && plan.electricPhoenixData) {
    EPAnnualSavings = Math.floor(epData.annualCost) - Math.floor(plan.electricPhoenixData.annualCost);
  }

  const isRecommendedPlan = planIndex === 0;

  return (
    <article className="card ep-card--active">
      {isRecommendedPlan && (
        <div className="ep-recommended-plan">OUR RECOMMENDED PLAN</div>
      )}

      <div className="card__section-item logo__container
        u-display-flex u-flex-direction-column u-align-items-center u-justify-content-flex-end
        u-align-items-center@md u-justify-content-center@md
        ep-card--dependent"
      >
        <img
          className="logo__image"
          src={supplier.logo}
          alt={supplier.name}
        />
        <div className="name">
          <strong>{name}</strong>
        </div>
        <Ratings
          ratings={supplier.ratings}
          boundary="scrollParent"
          tippyClass="ratings-tippy--card"
          supplierName={supplier.name}
          site={site}
        />
      </div>

      <div
        className="card__section card__section--name
        u-display-flex@md u-justify-content-space-between@md u-align-items-center@md ep-card--hide"
      >
        <div className="name">
          <strong>{name}</strong>
          {` - ${supplier.name}`}
        </div>
        <div className="term u-display-none u-display-block@md">
          <strong>{`Term: ${termText} | ${type}`}</strong>
        </div>
      </div>

      <div
        className="card__section
        u-position-relative
        u-display-flex
        u-justify-content-space-between
        u-flex-wrap-wrap
        u-justify-content-flex-start@md
        u-align-items-center@md"
      >
        <EpCardBillAmount
          number={EPAnnualSavings}
          text="estimated annual savings"
          infoText="Estimated annual savings is based on your new estimated bill compared with any bill
          data you provided, plus Electricity Information Administration (EIA) bill averages for your home profile."
          first
        />
        <EpCardBillAmount
          number={plan.electricPhoenixData.averageCost}
          text="estimated average bill"
          infoText="Estimated average bill is based on any usage data you entered,
          plus Electricity Information Administration (EIA) usage averages for your home profile."
        />
        <div className="card__section-item logo__container u-display-flex u-flex-direction-column
        u-align-items-flex-end u-justify-content-flex-start u-align-items-center@md
        u-justify-content-center@md ep-card--hide"
        >
          <img
            className="logo__image"
            src={supplier.logo}
            alt={supplier.name}
          />

          <Ratings
            ratings={supplier.ratings}
            boundary="scrollParent"
            tippyClass="ratings-tippy--card"
            supplierName={supplier.name}
            site={site}
          />
        </div>
        <Cta
          plan={plan}
          site={site}
          disableCartLinks={disableCartLinks}
          onOrderOnline={onOrderOnline}
          electricPhoenixActive={electricPhoenixActive}
          className="card__section-item"
          electricPhoenixInput={electricPhoenixInput}
          electricPhoenixToggleActive={electricPhoenixToggleActive}
        />
      </div>

      {/* PLAN DETAILS SECTION */}
      <div className="card__checkbox-row">
        <div className="details-container">
          <CardDetailsTrigger
            plan={plan}
            site={site}
            electricPhoenixActive={electricPhoenixActive}
          />
        </div>

        <div className="term u-display-none u-display-block@md ep-card--dependent ep-card__mobile-term">
          <strong>{`Term: ${termText}`}</strong>
        </div>
      </div>
    </article>
  );
}

EPCard.propTypes = {
  plan: PropTypes.shape({
    termText: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    supplier: PropTypes.shape({
      logo: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      ratings: PropTypes.shape({}),
    }),
    name: PropTypes.string.isRequired,
  }).isRequired,
  site: PropTypes.string.isRequired,
  onOrderOnline: PropTypes.func.isRequired,
  planIndex: PropTypes.number.isRequired,
  electricPhoenixActive: PropTypes.bool.isRequired,
  electricPhoenixToggleActive: PropTypes.bool.isRequired,
  electricPhoenixInput: PropTypes.shape({}).isRequired,
  epData: PropTypes.shape({
    annualCost: PropTypes.number.isRequired,
  }).isRequired,
  disableCartLinks: PropTypes.bool
};

EPCard.defaultProps = {
  disableCartLinks: false
};

export default EPCard;
