import React from 'react';
import PropTypes from 'prop-types';

import PricePerKwh from '../sub-components/PricePerKwh';
import Cta from '../sub-components/Cta';
import Badge from '../../Badge';
import TXUPlanDetailsModal from '../../TXUPlanDetailsModal';
import useModal from '../../../js/hooks/useModal';
import TXUPlanDetails from '../../grid-v2/TXUPlanDetails';
import { usePreamp } from '../../preamp';
import { useMonarch } from '../../monarch';
import IncentivesRibbon from '../sub-components/IncentivesRibbon';

function TXUCardV2({
  plan,
  site,
  onOrderOnline,
  disableCartLinks,
}) {
  const {
    termText,
    type,
    name,
  } = plan;
  const { rules } = useMonarch();
  const TXUBadge = usePreamp('Txu badges') || rules?.source?.txuBadge?.value;
  const formattedPlanName = name.replace(/[(]/, '<span>(').replace(/[)]/, ')<span>');
  const [isShowingModal, toggleModal] = useModal();
  const [isShowingDetails, toggleDetails] = useModal();

  return (
    <article
      className="cardv2"
    >
      <IncentivesRibbon variant="list" />
      <div className="cardv2__heading">
        <div className="cardv2__name" dangerouslySetInnerHTML={{ __html: formattedPlanName }} />
        <div className="cardv2__description">
          {plan.description}
        </div>

        {TXUBadge && plan?.badges?.length ? (
          <Badge type={plan?.badges[0]?.name}>
            { plan?.badges[0]?.attributeValue}
          </Badge>
        ) : null}
        <button
          type="button"
          className={`cardv2__details cardv2__details__desktop ${isShowingDetails ? 'expanded' : ''}`}
          aria-expanded={isShowingDetails}
          onClick={toggleDetails}
        >
          See Plan Details
          <span className="chevron-down" />
        </button>
        <hr className="hr-desktop" />
      </div>

      <section className="cardv2__price__section">
        <PricePerKwh
          plan={plan}
          site={site}
          className="cardv2__price"
          label="Price per"
        />

        <hr />

        <div className="cardv2__term">
          <span className="cardv2__term__label">Term</span>
          <span className="cardv2__term__type">{`${type} rate`}</span>
          <hr />
          <span className="cardv2__term__months">{termText}</span>
        </div>

        {plan?.badges?.length ? (
          <Badge type={plan?.badges[0]?.name}>
            { plan?.badges[0]?.attributeValue }
          </Badge>
        ) : null }
      </section>

      <div className="cardv2__cta">
        <Cta
          plan={plan}
          site={site}
          disableCartLinks={disableCartLinks}
          onOrderOnline={onOrderOnline}
          className="cardv2__cta__buttons"
        />

        <button
          type="button"
          className="cardv2__details cardv2__details__mobile"
          onClick={toggleModal}
        >
          See Plan Details
        </button>

        <button
          type="button"
          className={`cardv2__details cardv2__details__tablet ${isShowingDetails ? 'expanded' : ''}`}
          aria-expanded={isShowingDetails}
          onClick={toggleDetails}
        >
          See Plan Details
          <span className="chevron-down" />
        </button>
      </div>
      {isShowingDetails && (
        <TXUPlanDetails
          plan={plan}
          site={site}
          featured
          className="plan-modal plan-details-modal"
        />
      )}

      <TXUPlanDetailsModal
        detailsOpen={isShowingModal}
        plan={plan}
        site={site}
        handleDetailsToggle={toggleModal}
        className="cardv2__modal"
      />
    </article>
  );
}

TXUCardV2.propTypes = {
  plan: PropTypes.shape({}).isRequired,
  site: PropTypes.string.isRequired,
  onOrderOnline: PropTypes.func.isRequired,
  disableCartLinks: PropTypes.bool,
};

TXUCardV2.defaultProps = {
  disableCartLinks: false,
};

export default TXUCardV2;
